import {inject, observer} from "mobx-react";
import React, {Component} from "react";
import moment from "moment";
import navStore from "./domain/Nav";
import {TopAppBarActionItem} from '@rmwc/top-app-bar';
import MarkerWithLabel from "react-google-maps/lib/components/addons/MarkerWithLabel";
import {GoogleMap, Polyline, withGoogleMap, withScriptjs} from "react-google-maps";
import NavBar from "./NavBar";
import userStore from "./domain/User";
import {DrawerAppContent} from '@rmwc/drawer';
import MenuTrackHistory from "./TrackHistoryMenu";

let mapRef = null;

const MapWithAMarker = withScriptjs(withGoogleMap(props => {
    const options = {
        // minZoom: 15,
        // maxZoom: 21
    };

    return <GoogleMap
        defaultZoom={18}
        options={options}
        defaultCenter={{lat: props.latitude, lng: props.longitude}}
        center={{lat: props.latitude, lng: props.longitude}}
        ref={(ref) => {
            if (ref) mapRef = ref
        }}>
        {props.children}
    </GoogleMap>
}));

@inject("userStore", "navStore")
@observer
class TrackHistory extends Component {

    constructor(props) {
        super(props);

        this.state = {
            menuIsOpen: true,
            isMobile: false,
            contacts: [],
            contactsFiltered: [],
            error: false,
            errorMsg: "",
            confirm: false,
            selectedItem: {},
            trackHistory: [],
            historyList: [],
            latitude: navStore.navigation.params.latitude,
            longitude: navStore.navigation.params.longitude
        };

        this.loadDay = this.loadDay.bind(this);
        this.selectItem = this.selectItem.bind(this);
    }

    async componentDidMount() {
    }

    async loadDay(day) {
        const response = await userStore.getTrackHistory(navStore.navigation.params.phoneNumber, day);

        const trackHistory = response.map((track, index) => {
            let polyline = [];

            if (index > 0) {
                let previousTrack = response[index - 1];

                polyline.push({
                    lat: track.latitude,
                    lng: track.longitude
                });

                polyline.push({
                    lat: previousTrack.latitude,
                    lng: previousTrack.longitude
                });
            }

            return {
                point: {
                    id: track._id,
                    latitude: track.latitude,
                    longitude: track.longitude
                },
                polyline
            };
        });

        let historyList = response.map(track => {
            return {
                id: track._id,
                latitude: track.latitude,
                longitude: track.longitude,
                createdAt: moment(track.createdAt),
                address: track.address
            }
        });

        const newState = {
            trackHistory,
            historyList,
            latitude: navStore.navigation.params.latitude,
            longitude: navStore.navigation.params.longitude
        };

        this.setState(newState);

        if (historyList.length > 0) {
            this.selectItem(historyList[0]);
        }
    }

    async selectItem(item) {
        const newState = {
            selectedItem: item,
            latitude: item.latitude,
            longitude: item.longitude
        };

        if (!item.address) {
            newState.historyList = this.state.historyList.map(listItem => {
                if (listItem.id === item.id) {
                    listItem.addressMessage = 'Carregando...';
                }

                return listItem;
            });

            userStore.getTrackHistoryPointDetails(item.id).then(updatedItem => {
                const historyList = this.state.historyList.map(listItem => {
                    if (listItem.id === item.id) {
                        return {
                            id: updatedItem._id,
                            latitude: updatedItem.latitude,
                            longitude: updatedItem.longitude,
                            createdAt: moment(updatedItem.createdAt),
                            address: updatedItem.address
                        };
                    }

                    return listItem;
                });

                this.setState({historyList});
            }).catch(e => {
                const historyList = this.state.historyList.map(listItem => {
                    if (listItem.id === item.id) {
                        listItem.addressMessage = 'Erro. Clique para tentar novamente.';
                    }

                    return listItem;
                });

                this.setState({historyList});
            });
        }

        this.setState(newState);
    }

    render() {
        const inactiveMarkerStyling = {
            clear: "both", display: "inline-block", backgroundColor: "#959595", fontWeight: '500',
            color: "#FFFFFF", borderRadius: "20px",
            whiteSpace: "nowrap", width: "16px", height: "16px", textAlign: "center", overflow: "visible", opacity: 1
        };
        const markerStyling = {
            clear: "both", display: "inline-block", backgroundColor: "#004691", fontWeight: '500',
            color: "#FFFFFF", borderRadius: "20px",
            whiteSpace: "nowrap", width: "16px", height: "16px", textAlign: "center", overflow: "visible", opacity: 1
        };

        const polylineStyling = {
            strokeColor: '#004691',
            strokeOpacity: 1,
            strokeWeight: 2
        };

        const inactivePolylineStyling = {
            strokeColor: '#959595',
            strokeOpacity: 1,
            strokeWeight: 2
        };

        return [
            <NavBar label={"Histórico de localização"} menu={false}>
                <TopAppBarActionItem onClick={evt => {
                    this.props.navStore.gotoScreen(this.props.navStore.SCREEN.HOME)
                }}>close</TopAppBarActionItem>
            </NavBar>,
            <div className="app-container">
                <MenuTrackHistory open={this.state.menuIsOpen}
                                  dismissible={!this.state.isMobile}
                                  historyList={this.state.historyList}
                                  modal={this.state.isMobile}
                                  mapRef={this.getMapRef}
                                  selectedDayCallback={this.loadDay}
                                  selectedHistoryListCallback={this.selectItem}
                                  selectedItem={this.state.selectedItem}
                                  onClose={() => this.setState({menuIsOpen: false})}/>
                <DrawerAppContent tag="main" className="app__content">
                    <MapWithAMarker
                        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBlogweKPhxAblasHYXtYKRFcziuqQ_rA4&v=3.exp&libraries=geometry,drawing,places"
                        loadingElement={<div style={{height: `100%`}}/>}
                        containerElement={<div className="mapContainer"/>}
                        latitude={this.state.latitude}
                        longitude={this.state.longitude}
                        mapElement={<div style={{height: `100%`}}/>}>

                        {this.state.trackHistory.map((track, index) => {
                            const {point, polyline} = track;

                            return <div>
                                <MarkerWithLabel
                                    labelAnchor={new google.maps.Point(9, 9)}
                                    labelStyle={this.state.selectedItem.id === point.id ? markerStyling : inactiveMarkerStyling}
                                    icon={{
                                        url: '/none.svg',
                                    }}
                                    defaultZIndex={index * 10}  // Muda o Z-index do mark
                                    key={point.phoneNumber + index * 10} // Força o React a redraw o novo marker
                                    onClick={e => {
                                        this.selectItem(this.state.historyList[index]);
                                    }}
                                    position={{lat: point.latitude, lng: point.longitude}}>
                                    <div>

                                    </div>
                                </MarkerWithLabel>

                                {polyline && <Polyline
                                    path={polyline}
                                    options={this.state.selectedItem.id === point.id ? polylineStyling : inactivePolylineStyling}
                                />}
                            </div>
                        })}
                    </MapWithAMarker>
                </DrawerAppContent>
            </div>
        ];
    }
}


export function getMapRefa() {
    return mapRef
};
export default TrackHistory;
