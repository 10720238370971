import React, {Component} from 'react';
import './App.css';

import {configure as mobxconfigure} from 'mobx';
import {ThemeProvider} from '@rmwc/theme';
import {observer, Provider} from 'mobx-react';
import NavBar from "./NavBar";
import MenuApp from "./Menu";
import Container from "./Container";
import {TopAppBarActionItem} from '@rmwc/top-app-bar';
import {init as serviceInit} from './services';
import {Menu, MenuItem, MenuSurfaceAnchor} from '@rmwc/menu';
import {Snackbar} from '@rmwc/snackbar';
import {SimpleDialog} from '@rmwc/dialog';

import mapStore from './domain/Map';
import userStore from './domain/User';
import navStore from './domain/Nav';

import ConfigDefault from './ConfigDefault';
import Group from './Group';
import GroupChat from './GroupChat';
import GroupInfo from './GroupInfo';
import Config from './Config';
import ContactDetail from "./ContactDetail";
import TrackHistory from "./TrackHistory";
import TrackHistorySign from "./TrackHistorySign";


mobxconfigure({ enforceActions: "observed" })

let theme = {
    '--mdc-theme-primary': '#004691',
    '--mdc-theme-secondary': '#004691',
    'textPrimaryOnBackground' : "#ffffff"
};

serviceInit("/api/v1.0");
// if (window.location.port) {
//     serviceInit("http://localhost:3080/api/v1.0");
//     //serviceInit("https://timradar360.apps.prouser.co/api/v1.0");
// }
window.userStore = userStore;
window.navStore = navStore;
window.mapStore = mapStore;

@observer
class App extends Component {

    componentDidMount() {
        userStore.init().then(req => {
            userStore.getConfigDefault().then(config => {
                if (userStore.account.userLevel === 100 && (!config.workTime || !config.workTime.startHour)) {
                    navStore.gotoScreen(navStore.SCREEN.CONFIG_DEFAULT);
                }
            });
        });
        window.addEventListener('resize', () => this.doSizeCheck());
        this.doSizeCheck(true);

    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.menuIsOpen !== this.state.menuIsOpen) {
            setTimeout(() => {
                window.dispatchEvent(new Event('resize'));
            }, 300);
        }
    }

    state = {
        menuIsOpen: false,
        isMobile: true,
        theme: window.localStorage.getItem('rmwcTheme') || 'Baseline',
        confirm: false,
        IE11warning: false,
    };

    constructor(props) {
        super(props);
        this.getMapRef = this.getMapRef.bind(this);

        var ua = window.navigator.userAgent;
        var msie = ua.indexOf("Trident");
        if (msie > 0) {
            let warnd =  localStorage.getItem('IE11Warn');
            if (!warnd) {
                localStorage.setItem('IE11Warn',"true");
                setTimeout(() =>{
                    document.querySelector(".IE11Warn").className = "mdc-dialog mdc-dialog--open IE11Warn";
                    setTimeout(() =>{
                        document.querySelector(".IE11Warn").className = "mdc-dialog IE11Warn";
                    }, 4000);
                },2500);
            }
        }
    }

    doSizeCheck(initial) {
        const isMobile = window.innerWidth < 640;
        const menuIsOpen =
            initial && window.innerWidth > 640 ? true : this.state.menuIsOpen;

        if (
            this.state.isMobile !== isMobile ||
            this.state.menuIsOpen !== menuIsOpen
        ) {
            this.setState({isMobile, menuIsOpen});
        }
    }

    chatContainer() {
        if (navStore.navigation.screen !== navStore.SCREEN.GROUP_CHAT) {
            return null;
        }
        return <div className="modal" onClick={evt => navStore.gotoScreen(navStore.SCREEN.HOME,{})}>
            <GroupChat/>
        </div>;
    }

    groupInfoContainer() {
        if (navStore.navigation.screen !== navStore.SCREEN.GROUP_INFO) {
            return null;
        }
        return <div className="modal" onClick={evt => navStore.gotoScreen(navStore.SCREEN.HOME,{})}>
            <GroupInfo appRef={this}/>
        </div>;
    }

    contactDetailContainer() {
        if (navStore.navigation.screen !== navStore.SCREEN.MEMBER_DETAIL) {
            return null;
        }
        return <div className="modal" onClick={evt => navStore.gotoScreen(navStore.SCREEN.HOME,{})}>
            <ContactDetail chatUser={this.chatUser} />
        </div>;
    }

    signTrackHistoryContainer() {
        if (navStore.navigation.screen !== navStore.SCREEN.TRACK_HISTORY_SIGN) {
            return null;
        }
        return <div className="modal" onClick={evt => navStore.gotoScreen(navStore.SCREEN.HOME,{})}>
            <TrackHistorySign />
        </div>;
    }

    chatUser(name, phoneNumber) {
        let pu = userStore.account.phoneNumber;
        let groupId = "";
        if (pu > phoneNumber) {
            groupId = phoneNumber + "_" + pu;
        } else {
            groupId = phoneNumber + "_" + pu;
        }
        navStore.gotoScreen(navStore.SCREEN.GROUP_CHAT, {
            companyId: userStore.account.companyId,
            name: name,
            ownre: {},
            members: [],
            _id: groupId,
        });
    }

    getMapRef() {
        return this.mapRef;
    }

    get mapRef() {
        return this._mapRef;
    }


    mainContent = () => {
        return [
         this.chatContainer(),
         this.groupInfoContainer(),
         this.contactDetailContainer(),
         this.signTrackHistoryContainer(),
         <NavBar menu={true} onNavClick={evt => this.setState({menuIsOpen: !this.state.menuIsOpen})}>
             {userStore.account.userLevel >= 100 && <TopAppBarActionItem onClick={evt => navStore.gotoScreen(navStore.SCREEN.CONFIG_MEMBER,{})}>settings</TopAppBarActionItem>}
        </NavBar>,
        <div className="app-container">
            <MenuApp open={this.state.menuIsOpen}
            dismissible={!this.state.isMobile}
            modal={this.state.isMobile}
            mapRef={this.getMapRef}
            onClose={() => this.setState({menuIsOpen: false})}/>
            <Container ref={(ref) => { if (ref) { this._mapRef = ref.wrappedInstance;}}}/>
        </div>]
    }

    openGroup() {
        navStore.closeGroupMenu();
        navStore.gotoScreen(navStore.SCREEN.GROUP, navStore.navigation.groupMenu.group);
    }

    openChat() {
        navStore.closeGroupMenu();
        navStore.gotoScreen(navStore.SCREEN.GROUP_CHAT, navStore.navigation.groupMenu.group);
    }

    openGroupInfo() {
        navStore.closeGroupMenu();
        navStore.gotoScreen(navStore.SCREEN.GROUP_INFO, navStore.navigation.groupMenu.group);
    }

    render() {
        let {left, top} = navStore.navigation.groupMenu;
        //console.log("STate", this.state.IE11warning);
        return (
            <Provider mapStore={mapStore} userStore={userStore} navStore={navStore}>
            <ThemeProvider
                options={theme}
                className="app__root"
                tag="div"
            >

                <MenuSurfaceAnchor style={{zIndex: 100}}>
                    <Menu
                        style={{position: 'absolute', marginLeft: left + 'px', marginTop: top + 'px'}}
                        open={navStore.navigation.groupMenu.menuIsOpen}
                        fixed={true}
                        onSelect={evt => console.log(evt.detail.index)}
                        onClose={evt => navStore.closeGroupMenu()}
                    >
                        {userStore.account.userLevel >= 1 && <MenuItem className="menuSeparator menuItem" onClick={(evt) => this.openGroup()}>Editar</MenuItem>}
                        <MenuItem className="menuSeparator menuItem" onClick={(evt) => this.openChat()}>Enviar Mensagem</MenuItem>
                        <MenuItem className="menuItem" onClick={(evt) => this.openGroupInfo()}>Informações do Grupo</MenuItem>
                    </Menu>
                </MenuSurfaceAnchor>
                <Snackbar
                    show={mapStore.firebaseErrorStatus}
                    onHide={evt => this.setState({confirm: false})}
                    message="Sem conexão com o serviço. Verifique sua conexão a internet ou tente fazer novo login"
                    timeout={60000}
                    dismissesOnAction={false}
                    multiline={true}
                    style={{zIndex:1000}}
                    actionText="Sair"
                    actionHandler={() => {location.href = "/"}}
                />
                {navStore.navigation.screen > 0 && this.mainContent()}
                {navStore.navigation.screen === navStore.SCREEN.CONFIG_DEFAULT && <ConfigDefault/>}
                {navStore.navigation.screen === navStore.SCREEN.CONFIG_MEMBER  && <Config/>}
                {navStore.navigation.screen === navStore.SCREEN.GROUP  && <Group/>}
                {navStore.navigation.screen === navStore.SCREEN.TRACK_HISTORY  && <TrackHistory/>}
                <SimpleDialog
                    title="Aviso"
                    body="Você está utilizando um navegador antigo que tem problemas de performance e comportamento inconsistente. Recomenda-se utilizar um navegador mais recente para ter melhor experiência de uso."
                    open={this.state.IE11warning}
                    acceptLabel="Fechar"
                    cancelLabel={null}
                    className="IE11Warn"
                    onClose={evt => {
                        document.querySelector(".IE11Warn").className = "mdc-dialog IE11Warn";
                    }}
                />
            </ThemeProvider>
            </Provider>
        );
    }
}


export default App;
