import {timezone} from "./WorkDay";

export function formatPhone(p) {
    return "+" + p.substring(0,2) + " " + p.substring(2,4) + " " + p.substring(4);
}

export function formatWorkHour(member) {
    if (member && member.workTime && member.workTime.days && member.workTime.days.length) {
        if (member.workTime.days[0].initialLunch && member.workTime.days[0].initialLunch) {
            return member.workTime.days[0].startHour + " às " + member.workTime.days[0].initialLunch  +
             " e " + member.workTime.days[0].finalLunch + " às " + member.workTime.days[0].endHour + " " + timezone[member.workTime.days[0].timezone];
        }
        return member.workTime.days[0].startHour + " às " + member.workTime.days[0].endHour + " " + timezone[member.workTime.days[0].timezone];
    } else {
        return "Sem horário";
    }
}

export function currentWorkHour(member) {
    if (member && member.workTime && member.workTime.days && member.workTime.days.length) {
        let dayOfWeek =  new Date().getDay();
        let currentDay = member.workTime.days[0].weekDay.indexOf(dayOfWeek);
        if (currentDay > -1) {
            return "Visível das " + member.workTime.days[0].startHour + " às " + member.workTime.days[0].endHour;
        }
        return "Não vísivel hoje";
    } else {
        return "Sem horário";
    }
}

// Mesma cores utilizadas no app para icone de avatar
const defaultColors = [
    '#4a90e2', // cor do cliente
    '#2ecc71', // emerald
    '#3498db', // peter river
    '#8e44ad', // wisteria
    '#e67e22', // carrot
    '#e74c3c', // alizarin
    '#1abc9c', // turquoise
    '#2c3e50', // midnight blue
];

function sumChars(str) {
    let sum = 0;
    for (let i = 0; i < str.length; i++) {
        sum += str.charCodeAt(i);
    }

    return sum;
}

export function customColor(memberName) {
    let i = sumChars(memberName) % defaultColors.length;
    return defaultColors[i];
}
