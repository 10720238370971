import {observer, inject} from "mobx-react";
import {Component} from "react";
import {toJS, autorun} from 'mobx';
import NavBar from "./NavBar";
import { IconButton } from '@rmwc/icon-button';
import {TopAppBarActionItem} from '@rmwc/top-app-bar';
import {Elevation} from '@rmwc/elevation';
import {Switch} from '@rmwc/switch';
import {TextField, TextFieldIcon, TextFieldHelperText} from '@rmwc/textfield';
import {SimpleDialog} from '@rmwc/dialog';
import {
    List,
    ListItem,
    ListGroupSubheader,
    ListItemGraphic,
    ListDivider,
    ListItemMeta
} from '@rmwc/list';
import {Button, ButtonIcon} from '@rmwc/button';
import { Icon } from '@rmwc/icon';
import { View, Text } from 'react-native';
import React from "react";
import navStore from "./domain/Nav";
import WorkDay,{timezone} from "./WorkDay";
import SnoozeConfig from "./SnoozeConfig";
import {formatPhone, formatWorkHour} from './util';

const messages = [];

const iconNext = <svg version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 477.175 477.175" width="30px" height="30px">
<g>
	<path d="M360.731,229.075l-225.1-225.1c-5.3-5.3-13.8-5.3-19.1,0s-5.3,13.8,0,19.1l215.5,215.5l-215.5,215.5   c-5.3,5.3-5.3,13.8,0,19.1c2.6,2.6,6.1,4,9.5,4c3.4,0,6.9-1.3,9.5-4l225.1-225.1C365.931,242.875,365.931,234.275,360.731,229.075z   " fill="#004691"/>
</g>
</svg>;

const GroupMemberMenu = ({ photoUrl, label, member, phone, back }) => {
    if (!member) return null;
    let content = null;
    let visible = null;
    let className = "";
    if (photoUrl) {
        content = <div style={{width: "100%", height:"100%", backgroundSize:"contain", backgroundImage: "url(" + photoUrl + ")"}}>&nbsp;</div>;
    } else {
        content = <div className="profileLetterIcon" style={{width: "100%", height:"100%", backgroundSize:"contain"}}>{label[0].toUpperCase()}</div>;
    }
    if (member && member.workStatus === "work") {
        visible = <ListItemMeta icon="visibility" className="activeMetaIcon"/>;
        className = "activeMetaIcon";
    } else {
        visible = <ListItemMeta icon="visibility_off"/>;
    }
    return (
        <ListItem ripple={false} className={className}>
            {back && <Icon icon={iconNext} className="rmwc-icon material-icons mdc-list-item__graphic configBackIcon" onClick={evt => back()}/>}
            <ListItemGraphic icon={content} style={{backgroundColor: '#4a90e2'}} />
            <div className="groupListDiv">
            {label}<br/><span className="phoneNumber">{formatPhone(phone)}</span>
            </div>
        </ListItem>
    );
};

@inject("userStore", "navStore")
@observer
class AccountConfig extends Component {

    state = {
        contacts: [],
        contactsFiltered: [],
        error: false,
        errorMsg: "",
        confirm: false,
        messages: messages,
        showKind: false,
        localScreen: 0,

    };

    async componentDidMount() {
        this.saveData = this.saveData.bind(this);
        this.saveSnoozeData = this.saveSnoozeData.bind(this);
    }

    toggleMenu() {
        this.setState({showKind: !this.state.showKind});
    }

    getLevelClass(level) {
        if (this.props.member.userLevel === level) {
            return 'activeKind'
        } else {
            return 'selecteableKind'
        }
    }

    async  setLevel(level) {
        let account = await userStore.updateUserLevel(this.props.member.phoneNumber, level);
        userStore.updateContact(account);
    }

    async removeMember() {
        let account = await userStore.removeMember(this.props.member.phoneNumber);
        userStore.updateContact(account);
        navStore.gotoScreen(navStore.SCREEN.CONFIG_MEMBER,{});
        this.props.close();
    }

    workDayConfig() {
        if (this.state.localScreen !== 2) {
            return null;
        }
        let m = this.props.member;
        return <div className="modal groupListItem mdc-list--non-interactive mdc-list mdc-list--two-line mdc-list--avatar-list"
                    onClick={evt => { evt.stopPropagation(); this.setState({localScreen: 0, localMember: {}})}}>
            <WorkDay onSave={this.saveData} workTime={this.props.member.workTime.days[0]} noDefault={true} header={GroupMemberMenu({
                label: m.name,
                photoUrl: m.photoUrl,
                member: m,
                phone: m.phoneNumber,
                back: () => {this.setState({localScreen: 0})}
            })}/>
        </div>;

    }

    snoozeConfig() {
        if (this.state.localScreen !== 3) {
            return null;
        }
        let m = this.props.member;
        //console.log(JSON.stringify(this.props.member));
        return <div className="modal groupListItem mdc-list--non-interactive mdc-list mdc-list--two-line mdc-list--avatar-list"
                    onClick={evt => { evt.stopPropagation(); this.setState({localScreen: 0, localMember: {}})}}>
            <SnoozeConfig onSave={this.saveSnoozeData} snooze={this.props.member.workTime.snooze} noDefault={true} header={GroupMemberMenu({
                label: m.name,
                photoUrl: m.photoUrl,
                member: m,
                phone: m.phoneNumber,
                back: () => {this.setState({localScreen: 0})}
            })}/>
        </div>;

    }

    async saveData(config) {
        let account = await userStore.updateWorkTime(this.props.member.phoneNumber, 0, config);
        userStore.updateContact(account);
        this.setState({localScreen: 0});
    }

    async saveSnoozeData(config) {
        let account = await userStore.updateSnooze(this.props.member.phoneNumber, config);
        userStore.updateContact(account);
        this.setState({localScreen: 0});
    }

    confirmRemove() {
        this.setState({confirm: true});
    }

    render() {
        let className ='';
        let twoRow = "lineTwoRowClose";
        if (this.state.showKind) {
            twoRow = "lineTwoRow";
        }
        return [
            this.workDayConfig(),
            this.snoozeConfig(),
            <Elevation z={4} className="modalContent configMemberContainer" style={{padding: "0px"}}  onClick={e => {e.stopPropagation();}}>
            {/*<IconButton icon="close"*/}
                        {/*onClick={e => navStore.gotoScreen(navStore.SCREEN.HOME, {})}*/}
                        {/*style={{float: "right", marginRight: "-16px", marginTop: "-16px"}} />*/}
            <div className="contentCenter" style={{maxHeight:"calc(60vh-32px)", overflow:"hidden", padding: "0px"}}>
                <List twoLine avatarList className="groupListItem mdc-list--non-interactive">
                    <GroupMemberMenu label={this.props.member.name} photoUrl={this.props.member.photoUrl} phone={this.props.member.phoneNumber} member={this.props.member}/>
                    <ListDivider className={className}  />
                    <ListItem  ripple={false} className={className} onClick={evt => this.setState({localScreen: 2})}>
                        <div className="groupListDiv">
                            Horário de visibilidade<br/>
                            <span className="infoSubMenu">{formatWorkHour(this.props.member)}</span>
                        </div>
                        <Icon icon={iconNext}  className="rmwc-icon material-icons mdc-list-item__meta"/>
                    </ListItem>
                    <ListDivider className={className}  />
                    <ListItem  ripple={false} className={twoRow} onClick={evt => this.toggleMenu()}>
                        <div className="groupListDiv">
                            Tipo de Usuário<br/>
                            <span className="infoSubMenu">Admin, Gestor ou Funcionário</span>
                            <div className="configrMemberKind" style={{ display: (this.state.showKind?"block":"none")}}>
                                <ul>
                                    <li className={this.getLevelClass(0)} onClick={evt => this.setLevel(0)}>Funcionário</li>
                                    <li className={this.getLevelClass(10)} onClick={evt => this.setLevel(10)}>Gestor</li>
                                    <li className={this.getLevelClass(100)} onClick={evt => this.setLevel(100)}>Admin</li>
                                </ul>
                            </div>
                        </div>
                        <Icon icon={iconNext} className="rmwc-icon material-icons mdc-list-item__meta" />
                    </ListItem>
                    <ListDivider className={className}  />
                    <ListItem ripple={false} className={className} onClick={evt => this.setState({localScreen: 3})}>
                        <div className="groupListDiv">
                            Desabilitar temporariamente<br/>
                            <span className="infoSubMenu">
                                Está de férias, licença médica ou outro motivo<br/>
                                para não ser monitorado?
                            </span>
                        </div>
                        <Icon icon={iconNext} className="rmwc-icon material-icons mdc-list-item__meta"/>
                    </ListItem>
                    {/*<ListDivider className={className}  />*/}
                    {/*<ListItem ripple={false} className={className} onClick={evt => this.confirmRemove()}>*/}
                        {/*<div className="groupListDiv" style={{color:"#d0021b"}}>*/}
                            {/*Apagar usuário*/}
                        {/*</div>*/}
                    {/*</ListItem>*/}
                </List>

            </div>
            <SimpleDialog
                title="Atenção"
                body="Deseja realmente apagar esse usuário? Essa operação não pode ser desfeita e precisa ser confirmada junto a TIM para mreover o número."
                acceptLabel="Apagar"
                cancelLabel="Cancelar"
                open={this.state.confirm}
                onClose={evt => {
                    if(evt.detail.action === "accept") this.removeMember();
                    this.setState({confirm: false});
                }}
            />
        </Elevation>];
    }
}


export default AccountConfig;

