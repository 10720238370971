import axios from 'axios';

function createRequestFactory(urlBase, authOrg ){

    let interalURL = urlBase;
    let instance = axios.create({
        baseURL: urlBase,
        timeout: 30000,
    });
    let auth = authOrg;

    function request(method, path, obj) {
        return new Promise((resolve, reject) => {
            let options = {
                method: method,
                headers: {},
                maxContentLength: 2000000,

            };
            if (auth) {
                options.headers['x-token'] = auth;
            }
            let webtoken = localStorage.getItem("appToken");
            if (webtoken) {
                options.headers['apptoken'] = webtoken;
            }
            if (obj){
                options.headers['Accept'] = 'application/json';
                options.headers['Content-Type'] = 'application/json';
                options.data = JSON.stringify(obj);
            }
            options.url = path;
            instance.request(options)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error.response){
                        reject(error.response.data);
                    } else if (error.request){
                        reject({errorCode: -2, errorMsg: 'network'});
                    } else {
                        reject({errorCode: -1, errorMsg: 'network'});
                    }
                });
        });
    }

    function get(path,obj){
        return request('GET', path, obj);
    }

    function post(path,obj){
        return request('POST', path, obj);
    }

    function put(path,obj){
        return request('PUT', path, obj);
    }

    function deleteMethod(path,obj){
        return request('DELETE', path, obj);
    }

    function setAuth(authNew) {
        auth = authNew;
    }

    return {
        get: get,
        post: post,
        put: put,
        delete: deleteMethod,
        request: request,
        setAuth: setAuth,
    }

}


export default {createRequestFactory};
