import {observer, inject} from "mobx-react";
import {Component} from "react";
import { toJS, autorun } from 'mobx';
import NavBar from "./NavBar";
import { TopAppBarActionItem } from '@rmwc/top-app-bar';
import { Elevation } from '@rmwc/elevation';
import { Switch } from '@rmwc/switch';
import { TextField, TextFieldIcon, TextFieldHelperText } from '@rmwc/textfield';
import { Chip, ChipText, ChipIcon, ChipSet } from '@rmwc/chip';
import { SimpleDialog } from '@rmwc/dialog';
import {
    List,
    ListItem,
    ListGroupSubheader,
    ListItemGraphic,
    ListDivider,
    ListItemMeta
} from '@rmwc/list';
import { Button, ButtonIcon } from '@rmwc/button';
import React from "react";
import navStore from "./domain/Nav";


const mapChar = {
    "À": "A", "Á": "A", "Â": "A", "Ã": "A", "Ä": "A", "Å": "A", "Æ": "AE", "Ç": "C",
    "È": "E", "É": "E", "Ê": "E", "Ë": "E", "Ì": "I", "Í": "I", "Î": "I", "Ï": "I",
    "Ð": "D", "Ñ": "N", "Ò": "O", "Ó": "O", "Ô": "O", "Õ": "O", "Ö": "O", "Ø": "O",
    "Ù": "U", "Ú": "U", "Û": "U", "Ü": "U", "Ý": "Y", "ß": "s", "à": "a", "á": "a",
    "â": "a", "ã": "a", "ä": "a", "å": "a", "æ": "ae", "ç": "c", "è": "e", "é": "e",
    "ê": "e", "ë": "e", "ì": "i", "í": "i", "î": "i", "ï": "i", "ñ": "n", "ò": "o",
    "ó": "o", "ô": "o", "õ": "o", "ö": "o", "ø": "o", "ù": "u", "ú": "u", "û": "u",
    "ü": "u", "ý": "y", "ÿ": "y", "Ā": "A", "ā": "a", "Ă": "A", "ă": "a", "Ą": "A",
    "ą": "a", "Ć": "C", "ć": "c", "Ĉ": "C", "ĉ": "c", "Ċ": "C", "ċ": "c", "Č": "C",
    "č": "c", "Ď": "D", "ď": "d", "Đ": "D", "đ": "d", "Ē": "E", "ē": "e", "Ĕ": "E",
    "ĕ": "e", "Ė": "E", "ė": "e", "Ę": "E", "ę": "e", "Ě": "E", "ě": "e", "Ĝ": "G",
    "ĝ": "g", "Ğ": "G", "ğ": "g", "Ġ": "G", "ġ": "g", "Ģ": "G", "ģ": "g", "Ĥ": "H",
    "ĥ": "h", "Ħ": "H", "ħ": "h", "Ĩ": "I", "ĩ": "i", "Ī": "I", "ī": "i", "Ĭ": "I",
    "ĭ": "i", "Į": "I", "į": "i", "İ": "I", "ı": "i", "Ĳ": "IJ", "ĳ": "ij", "Ĵ": "J",
    "ĵ": "j", "Ķ": "K", "ķ": "k", "Ĺ": "L", "ĺ": "l", "Ļ": "L", "ļ": "l", "Ľ": "L",
    "ľ": "l", "Ŀ": "L", "ŀ": "l", "Ł": "l", "ł": "l", "Ń": "N", "ń": "n", "Ņ": "N",
    "ņ": "n", "Ň": "N", "ň": "n", "ŉ": "n", "Ō": "O", "ō": "o", "Ŏ": "O", "ŏ": "o",
    "Ő": "O", "ő": "o", "Œ": "OE", "œ": "oe", "Ŕ": "R", "ŕ": "r", "Ŗ": "R", "ŗ": "r",
    "Ř": "R", "ř": "r", "Ś": "S", "ś": "s", "Ŝ": "S", "ŝ": "s", "Ş": "S", "ş": "s",
    "Š": "S", "š": "s", "Ţ": "T", "ţ": "t", "Ť": "T", "ť": "t", "Ŧ": "T", "ŧ": "t",
    "Ũ": "U", "ũ": "u", "Ū": "U", "ū": "u", "Ŭ": "U", "ŭ": "u", "Ů": "U", "ů": "u",
    "Ű": "U", "ű": "u", "Ų": "U", "ų": "u", "Ŵ": "W", "ŵ": "w", "Ŷ": "Y", "ŷ": "y",
    "Ÿ": "Y", "Ź": "Z", "ź": "z", "Ż": "Z", "ż": "z", "Ž": "Z", "ž": "z", "ſ": "s",
    "ƒ": "f", "Ơ": "O", "ơ": "o", "Ư": "U", "ư": "u", "Ǎ": "A", "ǎ": "a", "Ǐ": "I",
    "ǐ": "i", "Ǒ": "O", "ǒ": "o", "Ǔ": "U", "ǔ": "u", "Ǖ": "U", "ǖ": "u", "Ǘ": "U",
    "ǘ": "u", "Ǚ": "U", "ǚ": "u", "Ǜ": "U", "ǜ": "u", "Ǻ": "A", "ǻ": "a", "Ǽ": "AE",
    "ǽ": "ae", "Ǿ": "O", "ǿ": "o"
};

function removeAccent(text) {
    var nonWord = /\W/g,
        mapping = function (c) {
            return mapChar[c] || c;
        };

    return text.replace(nonWord, mapping);
}

function upperWithoutAccent(text) {
    return removeAccent(text).toUpperCase();
}

@inject("userStore","navStore")
@observer
class App extends Component {

    state = {
        contacts: [],
        contactsFiltered: [],
        error: false,
        errorMsg: "",
        confirm: false,
    };

    static sample = 0;

    saveData = () => {
        let group = {
            id: "",
            name: "",
            members: []
        }

        if (!this.state.name) {
            this.setState({error: true, errorMsg: "O grupo deve ter um nome e ao menos um membro"});
            return;
        }
        group.name = this.state.name;

        if (navStore.navigation.params && navStore.navigation.params.name) {
            group.id = navStore.navigation.params._id;
        }

        for(let contact of this.contacts) {
            if (contact && contact.selected) group.members.push(contact.phoneNumber);
        }

        if (!group.members.length) {
            this.setState({error: true, errorMsg: "O grupo deve ter um nome e ao menos um membro"});
            return;
        }

        userStore.saveGroup(group).then(x => {
            navStore.gotoScreen(navStore.SCREEN.HOME,{});
        }).catch(e => {
            this.setState({error: true, errorMsg: "Ocorreu um erro. Tente novamente."});
        });
    }

    removeGroup = () => {
        userStore.deleteGroup(navStore.navigation.params._id).then(x => {
            navStore.gotoScreen(navStore.SCREEN.HOME,{});
        }).catch(e => {
            this.setState({error: true, errorMsg: "Ocorreu um erro. Tente novamente."});
        });
    };

    removeGroupConfirm = () => {
        this.setState({confirm: true});
    }

    async componentDidMount() {

        let contacts = await userStore.getContacts();
        this.contactsActive = contacts.filter(x => x.status === "active");
        this.initContacts();
    }

    initContacts() {
        this.members = [];
        if (navStore.navigation.params && navStore.navigation.params.name) {
            this.members = toJS(navStore.navigation.params.members);
            this.setState({name: navStore.navigation.params.name});
        }

        let contacts = [];
        for(let contact of this.contactsActive){
                contacts.push(
                    {
                        _id: contact._id,
                        phoneNumber: contact.phoneNumber,
                        name: contact.name,
                        userLevel: contact.userLevel,
                        selected: this.members.find(x => x && x.phoneNumber === contact.phoneNumber)
                    }
                )
        }
        this.setState({contacts: contacts});
        this.contacts = contacts;
        this.filterContacts('');

    }

    filterContacts(name) {
        this.name = name;
        let contacts = [];
        for(let contact of this.contacts){
            if (!name || upperWithoutAccent(contact.name).indexOf(upperWithoutAccent(name)) > -1) {
                contacts.push(contact)
            }
        }
        this.setState({contactsFiltered: contacts});

    }

    selectContact(contact) {
        let ct = this.contacts.find(x => x._id === contact._id);
        ct.selected = !ct.selected;
        this.setState({contacts: this.contacts});
    }

    render() {
        return [
            <NavBar>
                <TopAppBarActionItem  onClick={evt => {this.props.navStore.gotoScreen(this.props.navStore.SCREEN.HOME)}}>close</TopAppBarActionItem>
            </NavBar>,
            <div className="app-container configDefault">
                <SimpleDialog
                    title="Atenção"
                    body={this.state.errorMsg}
                    acceptLabel="Ok"
                    cancelLabel={null}
                    open={this.state.error}
                    onClose={evt => {
                        this.setState({error: false});
                    }}
                />
                <SimpleDialog
                    title="Atenção"
                    body="Deseja realmente apagar esse grupo? Essa operação não pode ser desfeita."
                    acceptLabel="Apagar"
                    cancelLabel="Cancelar"
                    open={this.state.confirm}
                    onClose={evt => {
                        if(evt.detail.action === "accept") this.removeGroup();
                        this.setState({confirm: false});
                    }}
                />
                <div className="contentFull">
                    <Elevation z={4} className="groupContainer">
                        <div className="contentCenter">Grupo</div>
                        <div className="contentLeft">
                            <div className="contentTitle">Nome:</div>
                            <div>
                                <TextField fullwidth={true} value={this.state.name}
                                           pattern="^.{2,}$" required
                                           onChange={evt => this.setState({name: evt.target.value})}/>
                                <TextFieldHelperText validationMsg>Mínimo 2 caracteres.</TextFieldHelperText>
                            </div>
                            <div  className="contentInfo">Você pode incluir qualquer usuário já convidado e ativo em sua conta. Para convidar novos usuários, contate o administrador.</div>
                            <div>
                                Selecionados:
                                <ChipSet>
                                    {this.state.contacts.map(m =>
                                        m.selected && <Chip><ChipText>{m.name}</ChipText></Chip>
                                    )}
                                </ChipSet>
                            </div>
                        </div>
                        <div className="contentRight">
                            <div className="contentTitle">Membros:</div>
                            <div>
                                <TextField withLeadingIcon="search" fullwidth={true}
                                           onChange={(evt) => this.filterContacts(evt.target.value)}
                                           withTrailingIcon="close" label="" className="searchField" />
                            </div>
                            <div className="groupMemberList">
                                <List twoLine avatarList className="groupListItem">
                                    {this.state.contactsFiltered.map(m =>
                                        <ListItem>
                                            <ListItemGraphic icon="person" style={{backgroundColor: 'lightgray'}} />
                                            <div className="groupListDiv">
                                                {m.name}<br/><span className="phoneNumber">{m.phoneNumber}</span>
                                            </div>
                                            {m.userLevel === 0 && <div className="userLevel">&nbsp;</div>}
                                            {m.userLevel === 10 && <div className="userLevel levelManager">Gestor</div>}
                                            {m.userLevel === 100 && <div className="userLevel levelAdmin">Admin</div>}
                                            <ListItemMeta className="groupListSwitch" icon={<Switch onClick={this.selectContact.bind(this,m)}  checked={m.selected}/>}/>
                                        </ListItem>
                                    )}
                                </List>
                            </div>
                        </div>
                        <div className="contentCenter">
                            <Button unelevated style={{width: "200px", height: "60px", marginTop: "24px"}} onClick={this.saveData.bind(this)}>SALVAR</Button>
                        </div>
                    </Elevation>
                    {navStore.navigation.params._id &&
                    <div className="groupContainer" style={{textAlign: "center"}}>
                        <Button outlined style={{width: "200px", height: "60px", marginTop: "24px", color:"red", borderColor:"red"}}
                                onClick={this.removeGroupConfirm.bind(this)}>APAGAR GRUPO</Button>
                    </div>}
                </div>
            </div>]
            ;
    }
}


export default App;

