import * as React from 'react';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';
import { inject, observer } from'mobx-react';
import { toJS, autorun, get } from 'mobx';
import { customColor} from "./util";

import { Drawer, DrawerContent, DrawerAppContent } from '@rmwc/drawer';
import { IconButton } from '@rmwc/icon-button';
import { Menu, MenuItem, MenuSurfaceAnchor, SimpleMenu } from '@rmwc/menu';
import {
    ListItem,
    ListGroupSubheader,
    ListItemGraphic,
    ListDivider,
    ListItemMeta
} from '@rmwc/list';
import { Typography } from '@rmwc/typography';
import { Ripple } from '@rmwc/ripple';
import { Icon } from '@rmwc/icon';

const GroupNewMenu = ({ url, label, userStore, userLevel }) => {
    return (
        <ListItem ripple={false}>
            <span className="groupHeader clientColor">{label}</span>
            {userLevel >= 10 && <ListItemMeta icon={<IconButton icon="add_circle_outline"
                                                                onClick={(evt) => navStore.gotoScreen(navStore.SCREEN.GROUP, {})}
                                                                iconOptions={{size: "large"}} className="clientColor groupMenuOptionPos"/>}/>}
        </ListItem>
    );
};

const DivMenu = ({ url, label, color }) => {
    let className = "groupMenuDiv";
    if (color) {
        className = "groupMenuDiv groupMenu2Div";
    }

    return (
        <ListDivider className={className}  />
    );
};

@inject("userStore","mapStore", "navStore")
@observer
class GroupFloatMenu  extends React.Component {

    constructor(props) {
        super(props);
        this.state = {menuIsOpen: false};
    }

    render () {
        let menu = (
            <IconButton icon="more_vert" iconOptions={{size: "large"}} className={["clientColor","groupMenuOptionPos"]}
                        onClick={evt =>  {
                            let {top, left} = evt.target.getBoundingClientRect();
                            this.props.navStore.openGroupMenu({'menuIsOpen': true, top: top, left: left, group: this.props.group});
                            }}
                            />
            );

        return (
            <ListItemMeta icon={menu}/>
        );
    }
}


const GroupHeaderMenu = observer((props) => {
    let header, items = [];
    let {group, userLevel, mapRef} = props;
    if (group) {
        header = <ListItem key={"group"+group._id} className="menuGroup"><span>{group.name}</span><GroupFloatMenu group={group} userLevel={userLevel}/></ListItem>;
        if (group.members) {
        let working = group.members.filter(member => member && get(member,"workStatus") === "work")
            let notWorking = group.members.filter(member => member && get(member,"workStatus") !== "work")
            items = [...working , ...notWorking ].map((member,index) => <GroupMemberMenu status={get(member,"workStatus")}  label={member.name} photoUrl={member.photoUrl} key={member._id} member={member} mapRef={mapRef}/>);
        }
    }
    return [header, ...items, <DivMenu key={20000} color="#d8d8d8"/>];
});

const GroupMemberMenu = observer(({ photoUrl, label, member, mapRef, status }) => {
    console.log(member);
    if (!member) return null;
    let content = null;
    let visible = null;
    let className = "";
    if (photoUrl) {
        content = <div style={{width: "100%", height:"100%", backgroundSize:"contain", backgroundImage: "url(" + photoUrl + ")"}}>&nbsp;</div>;
    } else {
        content = <div className="profileLetterIcon" style={{width: "100%", height:"100%", backgroundSize:"contain"}}>{label[0].toUpperCase()}</div>;
    }
    if (member && get(member,"workStatus") === "work") {
        visible = <ListItemMeta icon="visibility" className="activeMetaIcon"/>;
        className = "activeMetaIcon";
    } else {
        visible = <ListItemMeta icon="visibility_off"/>;
    }
    return (
        <ListItem className={className} key={member.phoneNumber + status} onClick={() => {
            mapRef().findUserInMap(member)
        }}>
            <ListItemGraphic icon={content} style={{backgroundColor: customColor(member.name)}} />
            {label}
            {visible}
        </ListItem>
    );
});

const GroupEmpty = ({userLevel}) => {
    if (userLevel) {
        return <div className="noGroup">
            <div>
                <img src="/app/images/blank_state.png"/>
            </div>
            <div>
                Crie grupos e inclua pessoas que já aceitaram o convite da sua conta. Membros do mesmo grupo podem se ver durante o horário comercial.
            </div>
        </div>
    }
    return <div className="noGroup">
        <div>
            <img src="/app/images/blank_state.png"/>
        </div>
        <div>
            Solicite ao gestor para incluí-lo em grupos. Membros do mesmo grupo podem se ver durante o horário comercial.
        </div>
    </div>
};

@inject("userStore","mapStore")
@observer
class MenuApp extends React.Component {
    static propTypes = {
        label: PropTypes.node
    };

    state = {
        isOpen: false
    };

    componentWillReact() {
    }

    render() {
        const { children, label } = this.props;
        let m = {label:"Teste", url:"/"};
        let {userStore, mapStore, ...p} = this.props;
        return (
            <React.Fragment>
                <Drawer id="main-nav" {...p}>
                    <DrawerContent id="main-nav-drawer" tag="div" className="mdc-list--non-interactive mdc-list--avatar-list">
                        <GroupNewMenu label="Grupos" url={m.url} userLevel={userStore.account.userLevel} key={1} userStore={this.props.userStore}/>
                        <DivMenu label={m.label} url={m.url} key={2} />
                        {userStore.groups.map(group => <GroupHeaderMenu key={group._id} group={group} userLevel={userStore.account.userLevel} mapRef={this.props.mapRef} />)}
                        {userStore.groups.length === 0 && <GroupEmpty userLevel={userStore.account.userLevel} />}

                        <GroupMemberMenu label={m.label} url={m.url} key={6} />
                    </DrawerContent>
                </Drawer>
            </React.Fragment>
        );
    }
}

@inject("userStore")
@inject("mapStore")
@observer
export class MenuHOC extends React.Component {
    static propTypes = {
        label: PropTypes.node
    };

    state = {
        isOpen: false
    };

    constructor(props) {
        super(props);

        this.props.userStore.init().then(account => {
            //this.props.mapStore.start(token);
        }).catch(e => {
            console.log(e);
        })
    }

    render() {
        let {userStore, mapStore, ...p} = this.props;
        return (<MenuApp {...p}/>);
    }

}

export default MenuHOC;
