import {observer, inject} from "mobx-react";
import {Component} from "react";
import NavBar from "./NavBar";
import { TopAppBarActionItem } from '@rmwc/top-app-bar';
import { Elevation } from '@rmwc/elevation';
import { Checkbox } from '@rmwc/checkbox';
import { TextField, TextFieldIcon, TextFieldHelperText } from '@rmwc/textfield';
import MenuApp from "./Menu";
import Container from "./Container";
import {toJS} from "mobx";
import React from "react";
import WorkDay from './WorkDay';


@inject("userStore","navStore")
@observer
class App extends Component {

    componentDidMount() {
        window.addEventListener('resize', () => this.doSizeCheck());
        this.doSizeCheck(true);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.menuIsOpen !== this.state.menuIsOpen) {
            setTimeout(() => {
                window.dispatchEvent(new Event('resize'));
            }, 300);
        }
    }

    state = {
        menuIsOpen: false,
        isMobile: true,
        theme: window.localStorage.getItem('rmwcTheme') || 'Baseline'
    };

    doSizeCheck(initial) {
        const isMobile = window.innerWidth < 640;
        const menuIsOpen =
            initial && window.innerWidth > 640 ? true : this.state.menuIsOpen;

        if (
            this.state.isMobile !== isMobile ||
            this.state.menuIsOpen !== menuIsOpen
        ) {
            this.setState({isMobile, menuIsOpen});
        }
    }

    saveData = (workDay) => {
        userStore.saveConfigDefault(workDay).then(data => {
            navStore.gotoScreen(navStore.SCREEN.HOME);
        }).catch(e => {
            //Exibir mensagem de erro.
            console.log("ERRO", e);
        });
    }

    render() {
        return [
            <NavBar onNavClick={evt => this.setState({menuIsOpen: !this.state.menuIsOpen})}>
                <TopAppBarActionItem>close</TopAppBarActionItem>
            </NavBar>,
            <div className="app-container configDefault">
                <div className="contentFull">
                    <div className="textFull">
                        <span className="menuItem">Por favor, defina um horário padrão de monitoramento dos seus funcionários</span><br/>&nbsp;<br/>

                        Todos os acessos irão ser monitorados no horário que for definido aqui, porém você poderá alterar o horário
                        de cada funcionário a qualquer momento, no Menu Conta, no alto da página à direita.

                        Lembre-se: horário de monitoramento é o horário em que você poderá ter acesso à localização do (s) seu (s)
                        funcionário (s). Recomendamos que seja igual ao horário de expediente.
                    </div>
                    <WorkDay onSave={this.saveData}/>
                </div>
            </div>]
        ;
    }
}


export default App;

