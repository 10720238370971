import {observer, inject} from "mobx-react";
import {Component} from "react";
import {toJS, autorun} from 'mobx';
import NavBar from "./NavBar";
import moment from "moment";
import { IconButton } from '@rmwc/icon-button';
import {TopAppBarActionItem} from '@rmwc/top-app-bar';
import {Elevation} from '@rmwc/elevation';
import {Switch} from '@rmwc/switch';
import {TextField, TextFieldIcon, TextFieldHelperText} from '@rmwc/textfield';
import {Chip, ChipText, ChipIcon, ChipSet} from '@rmwc/chip';
import {SimpleDialog} from '@rmwc/dialog';
import {
    List,
    ListItem,
    ListGroupSubheader,
    ListItemGraphic,
    ListDivider,
    ListItemMeta
} from '@rmwc/list';
import { Icon } from '@rmwc/icon';
import {Button, ButtonIcon} from '@rmwc/button';
import React from "react";
import navStore from "./domain/Nav";
import {formatPhone, currentWorkHour, customColor} from './util';


let battery20 = <svg xmlns="http://www.w3.org/2000/svg" fill="white" width="40" height="40" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><path d="M7 17v3.67C7 21.4 7.6 22 8.33 22h7.33c.74 0 1.34-.6 1.34-1.33V17H7z"/><path fillOpacity=".3" d="M17 5.33C17 4.6 16.4 4 15.67 4H14V3c0-.55-.45-1-1-1h-2c-.55 0-1 .45-1 1v1H8.33C7.6 4 7 4.6 7 5.33V17h10V5.33z"/></svg>;
let battery50 = <svg xmlns="http://www.w3.org/2000/svg" fill="white" width="40" height="40" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><path fillOpacity=".3" d="M17 5.33C17 4.6 16.4 4 15.67 4H14V3c0-.55-.45-1-1-1h-2c-.55 0-1 .45-1 1v1H8.33C7.6 4 7 4.6 7 5.33V13h10V5.33z"/><path d="M7 13v7.67C7 21.4 7.6 22 8.33 22h7.33c.74 0 1.34-.6 1.34-1.33V13H7z"/></svg>;
let battery80 = <svg xmlns="http://www.w3.org/2000/svg" fill="white" width="40" height="40" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><path fillOpacity=".3" d="M17 5.33C17 4.6 16.4 4 15.67 4H14V3c0-.55-.45-1-1-1h-2c-.55 0-1 .45-1 1v1H8.33C7.6 4 7 4.6 7 5.33V9h10V5.33z"/><path d="M7 9v11.67C7 21.4 7.6 22 8.33 22h7.33c.74 0 1.34-.6 1.34-1.33V9H7z"/></svg>;
let battery100 = <svg xmlns="http://www.w3.org/2000/svg" fill="white" width="40" height="40" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><path d="M15.67 4H14V3c0-.55-.45-1-1-1h-2c-.55 0-1 .45-1 1v1H8.33C7.6 4 7 4.6 7 5.33v15.33C7 21.4 7.6 22 8.34 22h7.32c.74 0 1.34-.6 1.34-1.33V5.33C17 4.6 16.4 4 15.67 4z"/></svg>;
let iconSMS = <svg xmlns="http://www.w3.org/2000/svg" width="54" height="54" viewBox="0 0 54 54">
    <g fill="none" fillRule="evenodd">
        <circle cx="27" cy="27" r="27" fill="#004691"/>
        <path fill="#FFF" d="M37.687 16H17.695C15.655 16 14 17.638 14 19.658v14.913c0 2.02 1.654 3.658 3.695 3.658h3.753v4.218a.744.744 0 0 0 .467.686c.281.114.604.05.818-.164l4.762-4.715v-.031h10.192c2.041 0 3.695-1.638 3.695-3.659V19.652C41.38 17.634 39.726 16 37.687 16zm-9.64 16.7H21.57c-.68 0-1.23-.545-1.23-1.218 0-.673.55-1.218 1.23-1.218h6.49c.68 0 1.231.545 1.231 1.218 0 .673-.55 1.218-1.23 1.218h-.013zm5.746-4.364h-12.21c-.68 0-1.23-.545-1.23-1.218 0-.673.55-1.219 1.23-1.219h12.21c.68 0 1.23.546 1.23 1.219s-.55 1.218-1.23 1.218zm0-4.364h-12.21c-.68 0-1.23-.546-1.23-1.219s.55-1.218 1.23-1.218h12.21c.68 0 1.23.545 1.23 1.218 0 .673-.55 1.219-1.23 1.219z"/>
    </g>
</svg>;
let iconHistory = <svg width="54px" height="54px" viewBox="0 0 54 54" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Web-App" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Tela-Principal-Gestor-(Detalhes-de-uma-pessoa)---upgrade-1" transform="translate(-566.000000, -224.000000)">
            <g id="Button-history" transform="translate(566.000000, 224.000000)">
                <g id="Button-SMS" fill="#004691">
                    <circle id="Oval-2-Copy-2" cx="27" cy="27" r="27"></circle>
                </g>
                <g id="history" transform="translate(10.000000, 12.000000)" fill="#FFFFFF">
                    <path d="M34,15.5 C34,24.0368108 27.12452,31 18.69524,31 C14.4568,31 10.59542,29.2352712 7.8642,26.421691 L10.2187,24.0368108 C12.38484,26.1827209 15.3986,27.5659239 18.69524,27.5659239 C25.28818,27.5659239 30.60918,22.1770449 30.60918,15.5 C30.60918,8.82295508 25.28818,3.43373173 18.69524,3.43373173 C12.90266,3.43373173 8.05256,7.48761496 6.96932,13.0197383 L10.78378,12.4474497 L5.36826,20.1258275 L0,12.4474497 L3.57884,12.9718754 C4.709,5.57998623 11.0194,0 18.69524,0 C27.12452,0 34,6.91532634 34,15.5 Z" id="Path" fill-rule="nonzero"></path>
                    <path d="M24.6095328,10.1434598 C26.6561489,9.31506554 28.0205596,12.3066553 26.0228484,13.1812376 L19.6880844,15.5743765 L18.3722267,18.9338093 C17.5925635,21.0508168 14.3764525,19.670492 15.1072109,17.7834278 L16.6176326,13.825212 C16.8125484,13.4108487 17.0563691,13.0888615 17.4462007,12.9506297 L24.6095328,10.1434598 Z" id="Path" fill-rule="nonzero"></path>
                </g>
            </g>
        </g>
    </g>
</svg> ;

@inject("userStore", "navStore")
@observer
class ContactDetail extends Component {

    state = {
        contacts: [],
        contactsFiltered: [],
        error: false,
        errorMsg: "",
        confirm: false,
    };

    async componentDidMount() {

        // let contacts = await userStore.getContacts();
        // this.contactsActive = contacts.filter(x => x.status === "active");
        // this.initContacts();
    }

    lastUpdate(updatedAt) {
        //console.log("DATA", updatedAt);
        if ((Date.now() - updatedAt) > 12 * 60 * 60 * 1000) {  // Acima de 12 horas
            let days = moment().startOf('day').diff(updatedAt,'day');
            if (moment().startOf('day').isSame(moment(updatedAt).startOf('day'),'day')) {
                return "hoje às " + moment(updatedAt).format('HH:mm');
            } if (moment().subtract(1,'day').startOf('day').isSame(moment(updatedAt).startOf('day'),'day')) {
                return "ontem às " + moment(updatedAt).format('HH:mm');
            }
            return moment(updatedAt).format('DD/MM/YYYY') + " às " + moment(updatedAt).format('HH:mm');
        }
        let data = " às " + moment(updatedAt).format('HH:mm');
        return data;
    }

    async trackHistoryClick() {
        const response = await userStore.hasTrackHistoryPlan(navStore.navigation.params.phoneNumber);
        const hasTrackHistory = response.hasTrackHistory;

        if (hasTrackHistory) {
            navStore.gotoScreen(navStore.SCREEN.TRACK_HISTORY, navStore.navigation.params);
        } else {
            navStore.gotoScreen(navStore.SCREEN.TRACK_HISTORY_SIGN, navStore.navigation.params);
        }
    }

    render() {
        let icon = null;
        let color = "green";

        if (navStore.navigation.params.batteryLevel < 10) { icon = battery20; color = "#dc3939"}
        if (navStore.navigation.params.batteryLevel >= 10) { icon = battery50; color = "#f5a623"}
        if (navStore.navigation.params.batteryLevel >= 20) { icon = battery80; color = "green"}

        let member = {};
        let group = userStore.groups.find(x => x.members.find(y => y.phoneNumber === navStore.navigation.params.phoneNumber));
        //console.log(group);
        if (group) {
            member = group.members.find(x => x.phoneNumber === navStore.navigation.params.phoneNumber);
        }
        let stylePhoto = {backgroundColor: customColor(navStore.navigation.params.name)};
        let imagePhoto = {backgroundImage: "url(" +
                ((navStore.navigation.params.offline && navStore.navigation.params.status !== 1)? navStore.navigation.params.photo.replace(".png","_96.png") : navStore.navigation.params.photo.replace(".png","_96.png"))
                + ")"};

        if (navStore.navigation.params.offline) {
            if (navStore.navigation.params.photo) {
                stylePhoto.backgroundColor = "transparent";
            }
        }

        let styleFinal = Object.assign({}, stylePhoto, imagePhoto);
        //console.log(styleFinal);
        //console.log(member);

        return (<Elevation z={4} className="modalContent chatContainer" onClick={e => {e.stopPropagation();}}>
            <IconButton icon="close"
                        className="modalCloseButton"
                        iconOptions={{size: "large"}}
                        onClick={e => navStore.gotoScreen(navStore.SCREEN.HOME, {})}
                        style={{float: "right", marginRight: "-16px", marginTop: "-16px"}} />
            <div className="contentCenter">
                <div className="contactPhotoDetail" style={styleFinal}>
                    {!navStore.navigation.params.photo && navStore.navigation.params.name[0].toUpperCase()}
                    {(navStore.navigation.params.offline) ? (navStore.navigation.params.photo) ? <div className="contactDetailsOfflinePhoto"></div> : <div className="contactDetailsOffline"></div> : null}
                </div>
                <div className="contactNameDetail">{navStore.navigation.params.name}</div>
                {navStore.navigation.params.offline && <div className="contactSubTextOffline">Está offline</div> }
                <div className="contactSubTextDetail">{currentWorkHour(member)}</div>
                <div className="contactSubTextDetail">Desde {this.lastUpdate(navStore.navigation.params.updatedAt)}</div>
            </div>
            <div className="contentCenter" style={{paddingTop: "8px"}}>
                <div className="contactBatteryDetail" style={{marginBottom: '20px'}}>
                    <div className="contactMessage"><ButtonIcon icon={iconHistory} onClick={evt => this.
                    trackHistoryClick()}/></div>
                </div>
                {navStore.navigation.params.batteryLevel >= 0 &&
                <div className="contactBatteryDetail">
                    <div className="contactBatteryLevel" style={{backgroundColor: color}}><Icon icon={icon} /></div>
                    <div className="contactBatteryText" style={{color: color}}>{Math.round(navStore.navigation.params.batteryLevel)}%</div>
                </div>}
                <div className="contactBatteryDetail">
                    <div className="contactMessage"><ButtonIcon icon={iconSMS} onClick={evt => this.props.chatUser(navStore.navigation.params.name,navStore.navigation.params.phoneNumber)}/></div>
                </div>
            </div>
            <div className="contentCenter">
            </div>
        </Elevation>);
    }
}


export default ContactDetail;

