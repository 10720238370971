import {observable, computed, action, runInAction, set} from 'mobx';
import services from '../services';
import MapStore from './Map';

let globalMonitorStatus = 0;

class UserStore  {

    @observable account = {};
    @observable groups = [];
    @observable webToken = {};
    @observable contacts = [];

    @action
    async init() {
        let account = await services.account.retriveAccount();
        runInAction(() => {
            this.account = account;
            this.groups = account.invites.groups;
            MapStore.start(account.webToken, account.phoneNumber);
            this.monitorWorkTime();
        });
    }

    async saveConfigDefault(workTime) {
        if (this.account && this.account.userLevel >= 100) {
            return services.config.saveConfig(workTime);
        }
        return Promise.reject();
    }

    async getConfigDefault() {
         return services.config.getConfig();
    }

    async getContacts() {
        if (this.contacts.length > 0) {
            return this.contacts;
        }
        let cts = await services.group.getContacts();
        this.contacts = cts.users;
        return this.contacts;
    }

    async getTrackHistory(phoneNumber, day) {
        return services.account.getTrackHistory(phoneNumber, day);
    }

    async hasTrackHistoryPlan(phoneNumber) {
        return services.account.hasTrackHistoryPlan(phoneNumber);
    }

    async subscribeToTrackHistoryTrial(phoneNumber) {
        return services.account.subscribeToTrackHistoryTrial(phoneNumber);
    }

    async getTrackHistoryPointDetails(id) {
        return services.account.getTrackHistoryPointDetails(id);
    }

    @action async updateContact(contact) {
        for(let ct of this.contacts) {
            if (ct.phoneNumber === contact.phoneNumber) {
                set(ct, {
                    userLevel: contact.userLevel,
                    workTime: contact.workTime,
                    status: contact.status,
                })
            }
        }
    }

    async saveGroup(group) {
        try{
            let newGroup = await services.group.saveGroup(group);
            await this.init();
            return newGroup;
        } catch (e) {
            console.log('Error',e);
            throw e;
        }
    }

    async deleteGroup(id)  {
        let newGroup = await services.group.deleteGroup(id);
    }

    async resendInvite(phoneNumber) {
        return await services.account.resendInvite(phoneNumber);
    }

    async getMessages(group) {
        return await services.group.getGroupMessages(group);
    }

    async sendMessage(group, message) {
        return await services.group.postGroupMessage(group,{message: message});
    }

    async updateUserLevel(phoneNumber, userLevel){
        return await services.account.updateUserLevel(phoneNumber, userLevel);
    }

    async updateWorkTime(phoneNumber, index, workTime){
        return await services.account.updateWorkTime(phoneNumber, index, workTime);
    }

    async updateSnooze(phoneNumber, workTime){
        return await services.account.updateSnooze(phoneNumber, workTime);
    }

    async removeMember(phoneNumber){
        return await services.account.removeMember(phoneNumber);
    }

    @action addUserTrack() {
        // TODO
    }

    @action monitorWorkTime() {
        let self = this;
        services.group.getGroupStatus().then(data => {
            console.log('Obtendo status corrente:', data);
            runInAction(() => {
                for(let item of data){
                    if (self.groups)
                    for(let group of self.groups.slice()) {  // Convert Array de Mobx$ em JS Array
                        if (group && group.members)
                        for(let member of group.members.slice()) {  // Convert Array de Mobx$ em JS Array
                            if (member && item && member.phoneNumber === item.phoneNumber) {
                                set(member, {
                                    workStatus: item.workStatus,
                                    workTime: item.workTime,
                                    name: item.name
                                });
                                //console.log(member);

                            }
                        }
                    }
                }
            });


        }).catch(e => console.log(e));

        if (!globalMonitorStatus) {
            globalMonitorStatus = setInterval(this.monitorWorkTime, 2 * 60 * 1000);
        }
    }
};


const defaultStore = new UserStore();

export default defaultStore;
