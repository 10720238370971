
import {observable, computed, action} from 'mobx';
import services from '../services';
const navStore = {
    @observable navigation: {
        screen: 1,
        params: null,
        groupMenu : {
            menuIsOpen: false,
            left: 0,
            top: 0,
        }
    },

    SCREEN: {
        HOME: 1,
        CONFIG_DEFAULT: -2,
        CONFIG_MEMBER : -3,
        GROUP : -4,
        TRACK_HISTORY : -5,
        TRACK_HISTORY_SIGN: 10,
        GROUP_CHAT : 5,
        GROUP_INFO : 6,
        MEMBER_DETAIL : 7,
        MEMBER_EDIT : 8
    },

    async init() {
    },

    @action gotoScreen(screen, params) {
        this.navigation.screen = screen;
        this.navigation.params = params;
    },

    @action openGroupMenu(params) {
        navStore.navigation.groupMenu.menuIsOpen = params.menuIsOpen;
        navStore.navigation.groupMenu.top = params.top;
        navStore.navigation.groupMenu.left = params.left;
        navStore.navigation.groupMenu.group = params.group;
    },

    @action closeGroupMenu() {
        navStore.navigation.groupMenu.menuIsOpen = false;
    }
};

export default navStore;
