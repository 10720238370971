import * as React from 'react';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';
import {
    TopAppBar,
    TopAppBarRow,
    TopAppBarSection,
    TopAppBarTitle,
    TopAppBarNavigationIcon,
    TopAppBarActionItem,
    TopAppBarFixedAdjust
} from '@rmwc/top-app-bar';

export class NavBar extends React.Component {
    static propTypes = {
        label: PropTypes.node
    };

    state = {
        isOpen: false
    };

    render() {
        const { children, label = "TIM RADAR 360" } = this.props;
        return (
            <React.Fragment>
                <TopAppBar fixed className="app__top-app-bar mdc-elevation--z4">
                    <TopAppBarRow>
                        <TopAppBarSection alignStart id="main_section">
                            {this.props.menu && <TopAppBarNavigationIcon onClick={this.props.onNavClick} icon="menu" />}
                            <TopAppBarNavigationIcon onClick={this.props.onNavClick} icon="menu"  icon="http://timradar360.apps.prouser.co/images/logo.png" id="logoMenu"/>
                            <TopAppBarTitle>
                                {label.toUpperCase()}
                            </TopAppBarTitle>
                        </TopAppBarSection>
                        <TopAppBarSection alignEnd>
                            {children}
                        </TopAppBarSection>
                    </TopAppBarRow>
                </TopAppBar>
                <TopAppBarFixedAdjust />
            </React.Fragment>
        );
    }
}

export default NavBar;
