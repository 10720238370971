import {observer, inject} from "mobx-react";
import {Component} from "react";
import {toJS, autorun} from 'mobx';
import NavBar from "./NavBar";
import { IconButton } from '@rmwc/icon-button';
import {TopAppBarActionItem} from '@rmwc/top-app-bar';
import {Elevation} from '@rmwc/elevation';
import {Switch} from '@rmwc/switch';
import {TextField, TextFieldIcon, TextFieldHelperText} from '@rmwc/textfield';
import {Chip, ChipText, ChipIcon, ChipSet} from '@rmwc/chip';
import {SimpleDialog} from '@rmwc/dialog';
import {
    List,
    ListItem,
    ListGroupSubheader,
    ListItemGraphic,
    ListDivider,
    ListItemMeta
} from '@rmwc/list';
import {Button, ButtonIcon} from '@rmwc/button';
import { View, Text } from 'react-native';
import {GiftedChat, Bubble, Send  } from './chat/GiftedChat'
import React from "react";
import navStore from "./domain/Nav";

const messages = [];

let iconSMS = <svg xmlns="http://www.w3.org/2000/svg" width="54" height="54" viewBox="0 0 54 54">
    <g fill="none" fill-rule="evenodd">
        <circle cx="27" cy="27" r="27" fill="#004691"/>
        <path fill="#FFF" d="M37.687 16H17.695C15.655 16 14 17.638 14 19.658v14.913c0 2.02 1.654 3.658 3.695 3.658h3.753v4.218a.744.744 0 0 0 .467.686c.281.114.604.05.818-.164l4.762-4.715v-.031h10.192c2.041 0 3.695-1.638 3.695-3.659V19.652C41.38 17.634 39.726 16 37.687 16zm-9.64 16.7H21.57c-.68 0-1.23-.545-1.23-1.218 0-.673.55-1.218 1.23-1.218h6.49c.68 0 1.231.545 1.231 1.218 0 .673-.55 1.218-1.23 1.218h-.013zm5.746-4.364h-12.21c-.68 0-1.23-.545-1.23-1.218 0-.673.55-1.219 1.23-1.219h12.21c.68 0 1.23.546 1.23 1.219s-.55 1.218-1.23 1.218zm0-4.364h-12.21c-.68 0-1.23-.546-1.23-1.219s.55-1.218 1.23-1.218h12.21c.68 0 1.23.545 1.23 1.218 0 .673-.55 1.219-1.23 1.219z"/>
    </g>
</svg>;

@inject("userStore", "navStore")
@observer
class GroupChat extends Component {

    state = {
        contacts: [],
        contactsFiltered: [],
        error: false,
        errorMsg: "",
        confirm: false,
        messages: messages
    };

    async componentDidMount() {

        let msgs = await userStore.getMessages(navStore.navigation.params._id);
        let messages = [];
        for(let m of msgs) {
            messages.push({
                _id: m._id,
                text: m.messageText,
                createdAt: new Date(m.messageDate),
                user: {
                    _id: m.phoneNumber,
                    name: m.name,
                },
            });
        }
        messages.sort((a,b) => a.createdAt - b.createdAt);
        this.setState({messages: messages})
    }


    async onSend(messages = []) {
        await userStore.sendMessage(navStore.navigation.params._id, messages[0].text);
        this.componentDidMount();
    }

    renderBubble(props) {
        const { user: self } = props;
        const { user = {} } = props.currentMessage;
        const { user: pUser = {} } = props.previousMessage;
        const isSameUser = pUser._id === user._id;
        const isSelf = user._id === self._id;
        const shouldRenderName = !isSameUser && !isSelf;
        const  styles = {
            userName: {
                marginLeft: 5,
                marginBottom: 3,
                color: '#b4b4b4'
            }
        };

        return (
            <View>
                {shouldRenderName &&
                <Text style={styles.userName}>{user.name}</Text>
                }
                <Bubble {...props} />
            </View>
        )
    }

    renderSend(props) {
        return (
            <Send
                {...props}
            >
                <View style={{marginRight: 10, marginBottom: 5}}>
                    <span>Enviar</span>
                </View>
            </Send>
        );
    }

    render() {
        return (<Elevation z={4} className="modalContent chatContainer" onClick={e => {e.stopPropagation();}}>
            <IconButton icon="close"
                        onClick={e => navStore.gotoScreen(navStore.SCREEN.HOME, {})}
                        style={{float: "right", marginRight: "-16px", marginTop: "-16px"}} />
            <div className="contentCenter">{navStore.navigation.params.name}</div>
            <div className="contentCenter">
                <List avatarList className="groupListItem mdc-list--non-interactive">
                    <ListItem ripple={false} disabled={true} className="mdc-list--non-interactive">
                        <div className="configHeaderList">
                            Membros:<br/>
                            <span className="configHeaderSubText">Para mais licenças, contate o gestor da sua conta</span>
                        </div>
                        <ListItemMeta className="configHeaderMetaItem" icon={<span className="configHeaderMeta">{navStore.navigation.params.members.length}</span>}/>
                    </ListItem>
                    <ListItem ripple={false} disabled={true} className="mdc-list--non-interactive">
                        <div className="configHeaderList">
                            Gestor do Grupo:
                        </div>
                    </ListItem>
                    <ListItem key={1}>
                        <ListItemGraphic icon="person" style={{backgroundColor: 'lightgray'}} />
                        <div className="groupListDiv">
                            {navStore.navigation.params.owner.name}<br/><span className="phoneNumber">{!navStore.navigation.params.owner.hidden && navStore.navigation.params.owner.phoneNumber}</span>
                        </div>
                    </ListItem>
                    <ListItem ripple={false} disabled={true} className="mdc-list--non-interactive">
                        <div className="configHeaderList" style={{width:"100%", textAlign: "center"}}>
                            <div className="contactMessage" style={{ height: 55 }}><ButtonIcon icon={iconSMS} onClick={evt => this.props.appRef.openChat()}/></div>
                        </div>
                    </ListItem>
                </List>
            </div>
        </Elevation>);
    }
}


export default GroupChat;

