import {observer, inject} from "mobx-react";
import {Component} from "react";
import { toJS, autorun } from 'mobx';
import NavBar from "./NavBar";
import { TopAppBarActionItem } from '@rmwc/top-app-bar';
import { Elevation } from '@rmwc/elevation';
import { Switch } from '@rmwc/switch';
import { IconButton } from '@rmwc/icon-button';
import { TextField, TextFieldIcon, TextFieldHelperText } from '@rmwc/textfield';
import { Chip, ChipText, ChipIcon, ChipSet } from '@rmwc/chip';
import { SimpleDialog } from '@rmwc/dialog';
import { Snackbar } from '@rmwc/snackbar';
import {
    List,
    ListItem,
    ListGroupSubheader,
    ListItemGraphic,
    ListDivider,
    ListItemMeta
} from '@rmwc/list';
import { Button, ButtonIcon } from '@rmwc/button';
import WorkDay from './WorkDay';
import React from "react";
import navStore from "./domain/Nav";
import AccountConfig from "./AccountConfig";
import {formatPhone} from './util';


@inject("userStore","navStore")
@observer
class Config extends Component {

    state = {
        localScreen: 0,
        localMember: {},
        contactsActive: [],
        contactsPending: [],
        error: false,
        errorMsg: "",
        confirm: false,
    };

    async componentDidMount() {

        let contacts = await userStore.getContacts();
        this.contactsActive = contacts.filter(x => x.status === "active");
        this.contactsPending = contacts.filter(x => x.status === "trial");
        this.setState({contactsActive: this.contactsActive, contactsPending: this.contactsPending, localScreen:0, localMember: {}});
    }


    reloadButton = (member) => {
        return (<IconButton icon="send"
                            style={{color: "#004691"}}
                            label="Enviar"
                            onClick={e => {userStore.resendInvite(member.phoneNumber).then(x => this.setState({confirm:true, confirmMsg:"SMS enviado para o contato"})); }}/>);
    }

    menuButton = (member) => {
        return (<IconButton icon="more_vert"
                            style={{color: "#004691"}}
                            onClick={e => { }}/>);
    }

    accountConfig() {
        if (this.state.localScreen !== 1) {
            return null;
        }
        return <div className="modal menuAccount" onClick={evt => this.setState({localScreen: 0, localMember: {}})}>
            <AccountConfig member={this.state.localMember} close={evt => this.setState({localScreen: 0, localMember: {}})}/>
        </div>;
    }

    editMember(member) {
        this.setState({localScreen:1, localMember: member});
    }

    render() {
        return [
            this.accountConfig(),
            <NavBar>
                <TopAppBarActionItem  onClick={evt => {this.props.navStore.gotoScreen(this.props.navStore.SCREEN.HOME)}}>close</TopAppBarActionItem>
            </NavBar>,
            <div className="app-container configDefault">
                <Snackbar
                    show={this.state.confirm}
                    onHide={evt => this.setState({confirm: false})}
                    message={this.state.confirmMsg}
                    timeout={1000}
                    dismissesOnAction={false}
                />
                <SimpleDialog
                    title="Atenção"
                    body={this.state.errorMsg}
                    acceptLabel="Ok"
                    cancelLabel={null}
                    open={this.state.error}
                    onClose={evt => {
                        this.setState({error: false});
                    }}
                />
                <div className="contentFull">
                    <Elevation z={4} className="configContainer">
                        <div className="contentCenter">Configurações</div>
                        <div className="contentCenter">
                            <List avatarList className="groupListItem mdc-list--non-interactive">
                                <ListItem ripple={false} disabled={true} className="mdc-list--non-interactive">
                                    <div className="configHeaderList">
                                        Licenças contratadas:<br/>
                                        <span className="configHeaderSubText">Para mais licenças, contate o gestor da sua conta</span>
                                    </div>
                                    <ListItemMeta className="configHeaderMetaItem" icon={<span className="configHeaderMeta">{this.state.contactsPending.length + this.state.contactsActive.length}</span>}/>
                                </ListItem>
                                <ListItem ripple={false} disabled={true} className="mdc-list--non-interactive">
                                    <div className="configHeaderList">
                                        Não Ativos:
                                    </div>
                                </ListItem>
                                {this.state.contactsPending.map(m =>
                                    <ListItem key={m.phoneNumber}>
                                        <ListItemGraphic icon="person" style={{backgroundColor: 'lightgray'}} />
                                        <div className="groupListDiv">
                                            {m.name}<br/><span className="phoneNumber">{formatPhone(m.phoneNumber)}</span>
                                        </div>
                                        {m.userLevel === 0 && <div className="userLevel">&nbsp;</div>}
                                        {m.userLevel === 10 && <div className="userLevel levelManager">Gestor</div>}
                                        {m.userLevel === 100 && <div className="userLevel levelAdmin">Admin</div>}
                                        <ListItemMeta className="groupListSwitch" icon={this.reloadButton(m)}/>
                                        <ListItemMeta className="groupListSwitch" icon={this.menuButton(m)} onClick={evt => this.editMember(m)}/>
                                    </ListItem>
                                )}
                                <ListItem ripple={false} disabled={true} className="mdc-list--non-interactive">
                                    <div className="configHeaderList">
                                        Usuários Ativos:
                                    </div>
                                </ListItem>
                                {this.state.contactsActive.map(m => m.status === "active" &&
                                    <ListItem>
                                        <ListItemGraphic icon="person" style={{backgroundColor: 'lightgray'}} />
                                        <div className="groupListDiv">
                                            {m.name}<br/><span className="phoneNumber">{formatPhone(m.phoneNumber)}</span>
                                        </div>
                                        {m.userLevel === 0 && <div className="userLevel">&nbsp;</div>}
                                        {m.userLevel === 10 && <div className="userLevel levelManager">Gestor</div>}
                                        {m.userLevel === 100 && <div className="userLevel levelAdmin">Admin</div>}
                                        <ListItemMeta className="groupListSwitch" icon={this.menuButton(m)} onClick={evt => this.editMember(m)}/>
                                    </ListItem>
                                )}
                            </List>

                        </div>
                    </Elevation>
                    <div className="configContainer" style={{textAlign: "center"}}>
                        <span className="configHeaderSubText">Qualquer dúvida, entre em contato com a central de atendimento TIM Empresas ligando *144 do seu TIM ou 1056 de qualquer telefone fixo</span>
                    </div>
                </div>
            </div>]
            ;
    }
}


export default Config;

