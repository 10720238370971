import {observable, computed, action} from 'mobx';
import services from '../services';

const userTrack = ({name, contactId, latitude, longitude, photo, updatedAt}) => {
    return {
        @observable name: name,
        @observable contactId: contactId,
        @observable latitude: latitude,
        @observable longitude: longitude,
        @observable photo: photo,
        @observable updatedAt: updatedAt,
        id: Math.random()
    };
};

const mapStore = {
    @observable tracks: [],
    @observable groups: [],
    @observable currentLocation: {},
    @observable firebaseErrorStatus: null,
    token: "",
    phoneNumber: "",
    callback: null,
    callbackChat: null,

    start(token, phoneNumber) {
        if (!this.token) {
            this.token = token;
            this.phoneNumber = phoneNumber;
            // inicia ouvindo o Firebase
            firebase.auth().signInWithCustomToken(token).then(function(x) {
                firebase.database().ref("/track/" + mapStore.phoneNumber).on("value", function(snapshot) {
                    var data = snapshot.val();
                    if (mapStore.callback) {
                        console.log('data', data);
                        mapStore.callback(data);
                    }
                });
                firebase.database().ref("/message/" + mapStore.phoneNumber).on("value", function(snapshot) {
                    var data = snapshot.val();
                    if (mapStore.callbackChat) {
                        console.log('data', data);
                        mapStore.callbackChat(data);
                    }
                });
                mapStore.updateFirebase(false);
            }).catch(function(error) {
                var errorCode = error.code;
                var errorMessage = error.message;
                console.log(errorCode, errorMessage);
                mapStore.updateFirebase(true);
            })
        }

    },

    watch(callback) {
        mapStore.callback = callback;
    },

    watchMessage(callbackChat) {
        mapStore.callbackChat = callbackChat;
    },

    @action updateFirebase(status) {
        mapStore.firebaseErrorStatus = status;
    },

    @action pokeContact(poke) {
        return services.account.pokeContacts(poke);
    },

    @action addUserTrack() {
        // TODO
    },
};

export default mapStore;
