import request from './request';
import moment from 'moment';

function createInstance(urlBase, auth, options) {

    let api = {}
    if (options && options.request) {
        api = options.request.createRequestFactory(urlBase, auth);
    } else {
        api = request.createRequestFactory(urlBase, auth);
    }

    function createAccount(obj){

    }

    function retriveAccount(obj) {
        return api.get('/account',obj);
    }


    function notifyContacts(contacts) {
        return api.post('/notify',contacts);
    }


    function sendRemindPin() {
        return api.get('/pin');
    }

    function sendRemind(phoneNumber) {
        return api.get('/invite/group/' + phoneNumber);
    }

    function updateToken(token) {
        api.setAuth(token);
    }

    function resendInvite(phoneNumber){
        return api.get('/invite/group/'+phoneNumber,{resend: true});
    }

    function sendBuzz(){
        return api.post('/buzz',{"buzz":true});
    }

    function terms(){
        return api.post('/terms',{"operator": true,"company": true});
    }

    function updateUserLevel(phoneNumber, userLevel){
        return api.post('/config/level',{"userLevel": userLevel,"phoneNumber": phoneNumber});
    }

    function updateWorkTime(phoneNumber, index, workTime) {
        return api.post('/config/worktime',{"index": index,"phoneNumber": phoneNumber, workTime: workTime});
    }

    function updateSnooze(phoneNumber, snooze) {
        return api.post('/config/snooze',{"phoneNumber": phoneNumber, snooze: snooze});
    }

    function removeMember(phoneNumber) {
        return api.delete('/member',{"phoneNumber": phoneNumber});
    }

    function pokeContacts(phones) {
        return api.put('/push', {phones:phones});
    }

    function getTrackHistory(phoneNumber, day) {
        const since = moment(day.format()).startOf('day').format('YYYY-MM-DD[ ]HH:mm:ss.SSS');
        const until = moment(day.format()).endOf('day').format('YYYY-MM-DD[ ]HH:mm:ss.SSS');

        return api.get(`/track/history/${phoneNumber}?since=${since}&until=${until}`);
    }

    function hasTrackHistoryPlan(phoneNumber) {
        return api.get(`/${phoneNumber}/has-track-history-plan`);
    }

    function subscribeToTrackHistoryTrial(phoneNumber) {
        return api.post(`/track/history/${phoneNumber}/trial`);
    }

    function getTrackHistoryPointDetails(id) {
        return api.get(`/track/history/${id}/details`);
    }

    return {
        createAccount: createAccount,
        retriveAccount: retriveAccount,
        sendRemind: sendRemind,
        notifyContacts: notifyContacts,
        updateToken: updateToken,
        sendRemindPin: sendRemindPin,
        resendInvite: resendInvite,
        sendBuzz: sendBuzz,
        terms: terms,
        updateUserLevel: updateUserLevel,
        updateWorkTime: updateWorkTime,
        updateSnooze: updateSnooze,
        removeMember: removeMember,
        pokeContacts: pokeContacts,
        getTrackHistory: getTrackHistory,
        hasTrackHistoryPlan: hasTrackHistoryPlan,
        subscribeToTrackHistoryTrial: subscribeToTrackHistoryTrial,
        getTrackHistoryPointDetails: getTrackHistoryPointDetails
    }

}

export default {
    createInstance: createInstance,
}
