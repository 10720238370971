import Account from './account';
import Group from './group';
import Config from './config';

let defaultInstance = {};

export function createInstance(urlBase, options) {

    let account = {};
    let group = {};
    let config = {};

    if (options && options.urls) {
        // caso tenhas ido fornecido já as URLs de cada API deve-se usá-lo
        // Caso contrário s eusa caminho "relativo "como uma unica API (monolitica)
        account = Account.createInstance(options.urls.account);
        group = Group.createInstance(options.urls.group);
        config = Config.createInstance(options.urls.config);
    } else {
        account = Account.createInstance(urlBase + '/account');
        group = Group.createInstance(urlBase + '/group');
        config = Config.createInstance(urlBase + '/config');
    }

    function updateToken(token){
        account.updateToken(token);
        group.updateToken(token);
        config.updateToken(token);
    }

    return {
        account, group, config, updateToken
    };

}

export function init(urlBase, options){
    defaultInstance = createInstance(urlBase, options);
}

export default new Proxy({account:1, group:1, config:1}, {
    get: function(target, prop, receiver) {
         try {
             return Reflect.get(defaultInstance, prop);
         } catch(e) {
             return defaultInstance[prop];
         }
    }
});


