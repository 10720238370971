import {inject, observer} from "mobx-react";
import React, {Component} from "react";
import {IconButton} from '@rmwc/icon-button';
import {Elevation} from '@rmwc/elevation';
import {Button} from '@rmwc/button';
import navStore from "./domain/Nav";

@inject("userStore", "navStore")
@observer
class TrackHistorySign extends Component {

    state = {
        contacts: [],
        contactsFiltered: [],
        error: false,
        errorMsg: "",
        confirm: false,
        buttonEnabled: true
    };

    async componentDidMount() {
        // let contacts = await userStore.getContacts();
        // this.contactsActive = contacts.filter(x => x.status === "active");
        // this.initContacts();
    }

    subscribe() {
        this.setState({
            buttonEnabled: false
        });

        userStore.subscribeToTrackHistoryTrial(navStore.navigation.params.phoneNumber).then(() => {
            navStore.gotoScreen(navStore.SCREEN.TRACK_HISTORY, navStore.navigation.params);
        }).catch(e => {
            alert(e.message);

            this.setState({
                buttonEnabled: true
            });
        });
    }

    render() {
        return (<Elevation z={4} className="modalContent chatContainer" onClick={e => {
            e.stopPropagation();
        }}>
            <IconButton icon="close"
                        className="modalCloseButton"
                        iconOptions={{size: "large"}}
                        onClick={e => navStore.gotoScreen(navStore.SCREEN.HOME, {})}
                        style={{float: "right", marginRight: "-16px", marginTop: "-16px", fontWeight: 'normal'}}/>
            <img src={'/app/images/track_history_sign_icon.png'}
                 style={{float: 'left'}}/>
            <div className="contentCenter" style={{marginLeft: '80px', marginRight: '80px'}}>
                Veja o histórico de localização dos seus funcionários
            </div>
            <div className="contentCenter">
                <img src={'/app/images/track_history_sign_map.png'}/>
            </div>
            <div className="contentCenter"
                 style={{marginLeft: '80px', marginRight: '80px', color: '#000', fontWeight: 'normal'}}>
                Essa funcionalidade faz parte do Plano Premium.Teste por 3 meses grátis. Depois, pague apenas R$1,90 por
                acesso.
            </div>
            <div className="contentCenter">
                <Button unelevated disabled={!this.state.buttonEnabled} style={{height: '60px', marginTop: "24px"}}
                        onClick={this.subscribe.bind(this)}>Quero testar por 3 meses grátis</Button>
            </div>
        </Elevation>);
    }
}


export default TrackHistorySign;
