

import request from './request';

function createInstance(urlBase, auth, options) {

    let api = {};
    if (options && options.request) {
        api = options.request.createRequestFactory(urlBase, auth);
    } else {
        api = request.createRequestFactory(urlBase, auth);
    }

    function retrieveContacts(obj){
        return api.get('/contacts');
    }

    function saveGroup({id, name, members}){
        return api.post('/', {id: id, name:name, members: members});
    }

    function updateToken(token) {
        api.setAuth(token);
    }

    function getGroupStatus() {
        return api.get('/status');
    }

    function deleteGroup(id) {
        return api.delete('/' + id);
    }

    function getGroupMessages(id) {
        return api.get(`/${id}/message`);
    }

    function postGroupMessage(id, obj) {
        return api.post(`/${id}/message`, obj);
    }

    function getContacts() {
        return api.get('/contacts');
    }

    return {
        retrieveContacts: retrieveContacts,
        updateToken: updateToken,
        getGroupStatus: getGroupStatus,
        deleteGroup: deleteGroup,
        saveGroup: saveGroup,
        getGroupMessages: getGroupMessages,
        postGroupMessage: postGroupMessage,
        getContacts: getContacts,
    }

}

export default {
    createInstance: createInstance,
}
