import {observer, Provider} from "mobx-react";
import {Component} from "react";
import NavBar from "./NavBar";
import {TopAppBarActionItem} from '@rmwc/top-app-bar';
import {Elevation} from '@rmwc/elevation';
import {Radio} from '@rmwc/radio';
import {TextField, TextFieldIcon, TextFieldHelperText} from '@rmwc/textfield';
import { Button, ButtonIcon } from '@rmwc/button';
import { Select } from '@rmwc/select';
import MenuApp from "./Menu";
import Container from "./Container";
import {toJS} from "mobx";
import React from "react";

@observer
class SnoozeConfig extends Component {

    constructor(props) {
        super(props);
        //console.log(this.props);
        if (this.props.snooze && this.props.snooze.until > Date.now()) {
            this.state = {
                until: this.props.snooze.until,
                snoozeType: this.props.snooze.snoozeType,
                untilDate: this.props.snooze.untilDate,
            };
        } else {
            this.state = {
                until: 0,
                snoozeType: 0,
            };
        }

    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState) {
    }

    reportWorkDay = () => {
        let snooze = {
            until : this.state.until,
            snoozeType: this.state.snoozeType,
            untilDate : this.state.untilDate,
        }

        //console.log(snooze);
        if (this.props.onSave) this.props.onSave(snooze);
    }

    render() {
        return (
            <Elevation z={4} className="workDaysContainer"  onClick={e => {e.stopPropagation();}}>
                <div className="workDays">
                    {this.props.header}
                </div>
                <h3>Desabilitar por:</h3>
                <Radio
                    value="1"
                    checked={this.state.snoozeType === '1'}
                    onChange={evt => this.setState({snoozeType: evt.target.value})}>
                    1 hora
                </Radio>

                <Radio
                    value="2"
                    checked={this.state.snoozeType === '2'}
                    onChange={evt => this.setState({snoozeType: evt.target.value})}>
                    2 horas
                </Radio>

                <Radio
                    value="3"
                    checked={this.state.snoozeType === '3'}
                    onChange={evt => this.setState({snoozeType: evt.target.value})}>
                    Até o fim do dia
                </Radio>

                <Radio
                    value="4"
                    checked={this.state.snoozeType === '4'}
                    onChange={evt => this.setState({snoozeType: evt.target.value})}>
                    Até o dia
                </Radio>
                <div className="snoozeConfigUntil">
                    <TextField fullwidth={true} value={this.state.untilDate}
                               type="date"
                               onChange={evt => this.setState({untilDate: evt.target.value, snoozeType: "4"})}/>
                </div>

                <Button unelevated style={{width: "100%", height: "60px", marginTop: "24px"}} onClick={this.reportWorkDay.bind(this)}>SALVAR</Button>

            </Elevation>

        )
    }
}

export default SnoozeConfig;


