import * as React from 'react';
import * as PropTypes from 'prop-types';
import {inject, observer} from 'mobx-react';
import moment from 'moment';
import classNames from 'classnames';
import 'moment/locale/pt-br';
import Select, {Option} from '@material/react-select';

import {Drawer, DrawerContent} from '@rmwc/drawer';
import {ListDivider} from '@rmwc/list';
import navStore from "./domain/Nav";

const DivMenu = ({url, label, color}) => {
    let className = "groupMenuDiv";
    if (color) {
        className = "groupMenuDiv groupMenu2Div";
    }

    return (
        <ListDivider className={className}/>
    );
};


const TrackHistoryHeaderMenu = observer((props) => {
    let {name, photo} = props.user;

    return <TrackHistoryMemberMenu label={name} photoUrl={photo}/>;
});

const TrackHistoryMemberMenu = observer(({photoUrl, label}) => {
    let content = null;

    if (photoUrl) {
        content = <i className="rmwc-icon material-icons mdc-list-item__graphic"
                     style={{backgroundColor: 'rgb(142, 68, 173)'}}>
            <div style={{
                width: "100%",
                height: "100%",
                backgroundSize: "contain",
                backgroundImage: "url(" + photoUrl + ")"
            }}>&nbsp;</div>
        </i>;
    } else {
        content = <i className="rmwc-icon material-icons mdc-list-item__graphic"
                     style={{backgroundColor: 'rgb(142, 68, 173)'}}>
            <div className="profileLetterIcon"
                 style={{width: "100%", height: "100%", backgroundSize: "contain"}}>{label[0].toUpperCase()}</div>
        </i>;
    }

    return (
        <div className="mdc-list-item" tabIndex="0">
            {content} {label}
        </div>
    );
});


@inject("userStore", "mapStore")
@observer
class MenuTrackHistory extends React.Component {
    static propTypes = {
        label: PropTypes.node
    };

    constructor(props) {
        super(props);

        let days = [];

        for (let i = 0; i < 7; i++) {
            let date = moment().startOf('day').subtract(i, 'days');

            let day = {
                label: date.format('DD[ de ]MMMM, YYYY'),
                date: date
            };

            days.push(day);
        }

        this.state = {
            isOpen: false,
            days,
            selectedDay: '0'
        };

        this.props.selectedDayCallback(moment(moment(days[0].date.format())));
    }

    render() {
        let m = {label: "Teste", url: "/"};
        let {userStore, mapStore, ...p} = this.props;

        return (
            <React.Fragment>
                <Drawer id="main-nav" {...p}>
                    <DrawerContent id="main-nav-drawer" tag="div"
                                   className="mdc-list--non-interactive mdc-list--avatar-list">
                        <TrackHistoryHeaderMenu user={navStore.navigation.params}/>
                        <DivMenu label={m.label} url={m.url} key={2}/>

                        <div className={'mdc-list-item'}>
                            <Select
                                enhanced
                                label='Dia'
                                value={this.state.selectedDay}
                                onEnhancedChange={(index) => {
                                    this.setState({selectedDay: index.toString()});
                                    this.props.selectedDayCallback(this.state.days[index].date);
                                }}>
                                {this.state.days.map((day, index) => {
                                    return <Option value={index.toString()}>{day.label}</Option>
                                })}
                            </Select>
                        </div>

                        {this.props.historyList.map((item, index, list) => {
                            return <div onClick={() => {
                                this.props.selectedHistoryListCallback(item)
                            }}>
                                {console.log('this.props.selectedItem === item.id')}
                                {console.log(this.props.selectedItem.id)}
                                {console.log(item.id)}
                                {console.log(this.props.selectedItem.id === item.id)}
                                {console.log('this.props.selectedItem === item.id')}

                                <div className={classNames('mdc-list-item', {'active': this.props.selectedItem.id === item.id})} style={{height: 'initial', minHeight: '56px'}}>
                                    <div>
                                        <span style={{fontSize: '12px', color: '#4a4a4a'}}>
                                            {item.createdAt.format('HH:mm')} Próximo a
                                        </span>
                                        <br/>
                                        {item.address && <span style={{fontSize: '18px', color: '#000'}}>
                                            {item.address}
                                        </span>}

                                        {item.addressMessage && <span style={{fontSize: '18px', color: '#000'}}>
                                            {item.addressMessage}
                                        </span>}
                                        {!item.address && !item.addressMessage &&
                                        <span style={{fontSize: '16px', color: '#000'}}>
                                            Clique para mais detalhes
                                        </span>}
                                    </div>
                                </div>

                                {index < list.length - 1 &&
                                <div className="trackHistoryMenuDiv mdc-list-divider"
                                     style={{borderColor: "#F00 !important"}}>
                                </div>
                                }
                            </div>
                        })}

                    </DrawerContent>
                </Drawer>
            </React.Fragment>
        );
    }
}

@inject("userStore")
@inject("mapStore")
@observer
export class MenuTrackHistoryHOC extends React.Component {
    static propTypes = {
        label: PropTypes.node
    };

    state = {
        isOpen: false
    };

    constructor(props) {
        super(props);

        this.props.userStore.init().then(account => {
            //this.props.mapStore.start(token);
        }).catch(e => {
            console.log(e);
        })
    }

    render() {
        let {...p} = this.props;

        return (
            <MenuTrackHistory {...p} />);
    }

}

export default MenuTrackHistoryHOC;
