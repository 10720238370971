import request from './request';

function createInstance(urlBase, auth, options) {

    let api = {};
    if (options && options.request) {
        api = options.request.createRequestFactory(urlBase, auth);
    } else {
        api = request.createRequestFactory(urlBase, auth);
    }

    function getConfig(obj){
        return api.get('/');
    }

    function saveConfig(workTime){
        return api.post('/', {workTime: workTime});
    }


    return {
        getConfig: getConfig,
        saveConfig: saveConfig,
    }

}

export default {
    createInstance: createInstance,
}
