import {observer, inject} from "mobx-react";
import {Component} from "react";
import {toJS, autorun} from 'mobx';
import NavBar from "./NavBar";
import { IconButton } from '@rmwc/icon-button';
import {TopAppBarActionItem} from '@rmwc/top-app-bar';
import {Elevation} from '@rmwc/elevation';
import {Switch} from '@rmwc/switch';
import {TextField, TextFieldIcon, TextFieldHelperText} from '@rmwc/textfield';
import {Chip, ChipText, ChipIcon, ChipSet} from '@rmwc/chip';
import {SimpleDialog} from '@rmwc/dialog';
import {
    List,
    ListItem,
    ListGroupSubheader,
    ListItemGraphic,
    ListDivider,
    ListItemMeta
} from '@rmwc/list';
import {Button, ButtonIcon} from '@rmwc/button';
import { View, Text } from 'react-native';
import {GiftedChat, Bubble, Send  } from './chat/GiftedChat'
import React from "react";
import navStore from "./domain/Nav";

const messages = [];

@inject("userStore", "navStore", "mapStore")
@observer
class GroupChat extends Component {

    state = {
        contacts: [],
        contactsFiltered: [],
        error: false,
        errorMsg: "",
        confirm: false,
        messages: messages
    };

    constructor(props) {
        super(props);
        this.watchMessage = this.watchMessage.bind(this);
        this.fetchMessages = this.fetchMessages.bind(this);
    }

    async componentDidMount() {
        await this.fetchMessages();
        this.props.mapStore.watchMessage(this.watchMessage);
    }

    async fetchMessages() {
        let msgs = await userStore.getMessages(navStore.navigation.params._id);
        let messages = [];
        for(let m of msgs) {
            messages.push({
                _id: m._id,
                text: m.messageText,
                createdAt: new Date(m.messageDate),
                user: {
                    _id: m.phoneNumber,
                    name: m.name,
                },
            });
        }
        messages.sort((a,b) => a.createdAt - b.createdAt);
        this.setState({messages: messages})
    }

    watchMessage(data) {
        //console.log(data);
        let msg = data[navStore.navigation.params._id];
        if (msg) {
            if (msg.count > 0) {
                this.fetchMessages();
            }
        }
    }

    async onSend(messages = []) {
        await userStore.sendMessage(navStore.navigation.params._id, messages[0].text);
        this.fetchMessages();
    }

    renderBubble(props) {
        const { user: self } = props;
        const { user = {} } = props.currentMessage;
        const { user: pUser = {} } = props.previousMessage;
        const isSameUser = pUser._id === user._id;
        const isSelf = user._id === self._id;
        const shouldRenderName = !isSameUser && !isSelf;
        const  styles = {
            userName: {
                marginLeft: 5,
                marginBottom: 3,
                color: '#b4b4b4'
            }
        };

        return (
            <View>
                {shouldRenderName &&
                <Text style={styles.userName}>{user.name}</Text>
                }
                <Bubble {...props} />
            </View>
        )
    }

    renderSend(props) {
        return (
            <Send
                {...props}
            >
                <View style={{marginRight: 10, marginBottom: 5}}>
                    <span>Enviar</span>
                </View>
            </Send>
        );
    }

    render() {
        return (<Elevation z={4} className="modalContent chatContainer" onClick={e => {e.stopPropagation();}}>
                <IconButton icon="close"
                            onClick={e => navStore.gotoScreen(navStore.SCREEN.HOME, {})}
                            style={{float: "right", marginRight: "-16px", marginTop: "-16px"}} />
                <div className="contentCenter">{navStore.navigation.params.name}</div>
                <div className="contentCenter" style={{maxHeight:"calc(60vh-32px)", overflow:"hidden"}}>
                    <GiftedChat
                        messages={this.state.messages}
                        renderLoading={() => <span>Carregando....</span>}
                        renderSend={this.renderSend}
                        onSend={messages => this.onSend(messages)}
                        alwaysShowSend={true}
                        inverted={false}
                        user={{
                            _id: userStore.account.phoneNumber,
                            name: userStore.account.name
                        }}
                        // renderBubble={this.renderBubble}
                        renderAvatar={null}
                        placeholder="Escreva aqui sua mensagem…"
                    />
                </div>
            </Elevation>);
    }
}


export default GroupChat;

