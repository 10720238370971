import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import '@material/button/dist/mdc.button.css';
import '@material/card/dist/mdc.card.css';
import '@material/checkbox/dist/mdc.checkbox.css';
import '@material/chips/dist/mdc.chips.css';
import '@material/dialog/dist/mdc.dialog.css';
import '@material/drawer/dist/mdc.drawer.css';
import '@material/elevation/dist/mdc.elevation.css';
import '@material/fab/dist/mdc.fab.css';
import '@material/form-field/dist/mdc.form-field.css';
import '@material/grid-list/dist/mdc.grid-list.css';
import '@material/icon-button/dist/mdc.icon-button.css';
import '@material/image-list/dist/mdc.image-list.css';
import '@material/layout-grid/dist/mdc.layout-grid.css';
import '@material/linear-progress/dist/mdc.linear-progress.css';
import "@material/react-list/dist/list.css";
import "@material/react-menu-surface/dist/menu-surface.css";
import "@material/react-menu/dist/menu.css";
import "@material/react-select/dist/select.css";
import '@material/radio/dist/mdc.radio.css';
import '@material/ripple/dist/mdc.ripple.css';
import '@material/switch/dist/mdc.switch.css';
import '@material/slider/dist/mdc.slider.css';
import '@material/snackbar/dist/mdc.snackbar.css';
import '@material/tab-bar/dist/mdc.tab-bar.css';
import '@material/tab/dist/mdc.tab.css';
import '@material/tab-scroller/dist/mdc.tab-scroller.css';
import '@material/tab-indicator/dist/mdc.tab-indicator.css';
import '@material/textfield/dist/mdc.textfield.css';
import '@material/theme/dist/mdc.theme.css';
import '@material/toolbar/dist/mdc.toolbar.css';
import '@material/top-app-bar/dist/mdc.top-app-bar.css';
import '@material/typography/dist/mdc.typography.css';

// Codigo para o letargico IE11
if (!Object.entries)
    Object.entries = function( obj ){
        var ownProps = Object.keys( obj ),
            i = ownProps.length,
            resArray = new Array(i); // preallocate the Array
        while (i--)
            resArray[i] = [ownProps[i], obj[ownProps[i]]];

        return resArray;
    };

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
