import {observer, Provider} from "mobx-react";
import {Component, Fragment} from "react";
import NavBar from "./NavBar";
import {TopAppBarActionItem} from '@rmwc/top-app-bar';
import {Elevation} from '@rmwc/elevation';
import {Checkbox} from '@rmwc/checkbox';
import {TextField, TextFieldIcon, TextFieldHelperText} from '@rmwc/textfield';
import { Button, ButtonIcon } from '@rmwc/button';
import { Select } from '@rmwc/select';
import MenuApp from "./Menu";
import Container from "./Container";
import {toJS} from "mobx";
import React from "react";

export const timezone = {
    "America/Araguaina" : "Américas / Araguaina",
    "America/Bahia" : "Américas / Bahia",
    "America/Belem" : "Américas / Belem",
    "America/Boa_Vista" : "Américas / Boa Vista",
    "America/Campo_Grande" : "Américas / Campo Grande",
    "America/Cuiaba" : "Américas / Cuiabá",
    "America/Eirunepe" : "Américas / Eirunepe",
    "America/Fortaleza" : "Américas / Fortaleza",
    "America/Maceio" : "Américas / Maceió" ,
    "America/Manaus" : "Américas / Manaus",
    "America/Noronha" : "Américas / Fernando de Noronha",
    "America/Porto_Velho" : "Américas / Porto Velho",
    "America/Recife" : "Américas / Recife",
    "America/Rio_Branco" : "Américas / Rio Branco",
    "America/Sao_Paulo" : "Américas / São Paulo",
    "America/Santarem" : "Américas / Santarém",
}

const regexHour = "(2[0-3]|[01]?[0-9]):([0-5]?[0-9])";

@observer
class WorkDay extends Component {

    constructor(props) {
        super(props);
        if (this.props.workTime && this.props.workTime.startHour) {
            this.state = {
                inicio: this.props.workTime.startHour,
                fim: this.props.workTime.endHour,
                fuso: this.props.workTime.timezone,
                dom: this.props.workTime.weekDay.find(x => x === 0) === 0,
                seg: this.props.workTime.weekDay.find(x => x === 1) === 1,
                ter: this.props.workTime.weekDay.find(x => x === 2) === 2,
                qua: this.props.workTime.weekDay.find(x => x === 3) === 3,
                qui: this.props.workTime.weekDay.find(x => x === 4) === 4,
                sex: this.props.workTime.weekDay.find(x => x === 5) === 5,
                sab: this.props.workTime.weekDay.find(x => x === 6) === 6,
                intervalo: this.props.workTime.lunch,
                initialIntervalo: this.props.workTime.initialLunch,
                finalIntervalo: this.props.workTime.finalLunch
            };
        } else if (this.props.noDefault) {
            this.state = {
                dom: false,
                seg: false,
                ter: false,
                qua: false,
                qui: false,
                sex: false,
                sab: false,
                inicio: "",
                fim: "",
                fuso: "America/Sao_Paulo",
                intervalo: false,
                initialIntervalo: "",
                finalIntervalo: ""
            };
        } else {
            this.state = {
                dom: false,
                seg: true,
                ter: true,
                qua: true,
                qui: true,
                sex: true,
                sab: false,
                inicio: "08:00",
                fim: "18:00",
                fuso: "America/Sao_Paulo",
                intervalo: true,
                initialIntervalo: "12:00",
                finalIntervalo: "13:00"
            };
        }

    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState) {
    }

    reportWorkDay = () => {
        let workDay = {
            weekDay : [],
            startHour: "",
            endHour : "",
            timezone : "America/Sao_Paulo"
        }

        let testHour = RegExp(regexHour);

        if (!this.state.inicio || !testHour.test(this.state.inicio)) {
            return;
        }
        workDay.startHour = this.state.inicio;

        if (!this.state.fim || !testHour.test(this.state.fim)) {
            return;
        }
        workDay.endHour = this.state.fim;

        if (this.state.dom) workDay.weekDay.push(0);
        if (this.state.seg) workDay.weekDay.push(1);
        if (this.state.ter) workDay.weekDay.push(2);
        if (this.state.qua) workDay.weekDay.push(3);
        if (this.state.qui) workDay.weekDay.push(4);
        if (this.state.sex) workDay.weekDay.push(5);
        if (this.state.sab) workDay.weekDay.push(6);

        workDay.timezone = this.state.fuso;

        workDay.lunch = this.state.intervalo;

        if (workDay.lunch) {
            if (!this.state.initialIntervalo || !testHour.test(this.state.initialIntervalo)) {
                return;
            }
            workDay.initialLunch = workDay.lunch ? this.state.initialIntervalo : "";

            if (!this.state.finalIntervalo || !testHour.test(this.state.finalIntervalo)) {
                return;
            }
            workDay.finalLunch = workDay.lunch ? this.state.finalIntervalo : "";
        } else {
            workDay.initialLunch = "";
            workDay.finalLunch = "";
        }

        if (this.props.onSave) this.props.onSave(workDay);
    }

    render() {
        return (
            <Elevation z={4} className="workDaysContainer"  onClick={e => {e.stopPropagation();}}>
                <div className="workDays">
                    {this.props.header}
                    <div className="workDay">
                        <Checkbox
                            checked={this.state.dom}
                            onChange={evt => this.setState({dom: evt.target.checked})}>
                        </Checkbox>
                        <div className="workDayLabel">Dom</div>
                    </div>
                    <div className="workDay">
                        <Checkbox
                            checked={this.state.seg}
                            onChange={evt => this.setState({seg: evt.target.checked})}>
                        </Checkbox>
                        <div className="workDayLabel">Seg</div>
                    </div>
                    <div className="workDay">
                        <Checkbox
                            checked={this.state.ter}
                            onChange={evt => this.setState({ter: evt.target.checked})}>
                        </Checkbox>
                        <div className="workDayLabel">Ter</div>
                    </div>
                    <div className="workDay">
                        <Checkbox
                            checked={this.state.qua}
                            onChange={evt => {this.setState({qua: evt.target.checked})}}>
                        </Checkbox>
                        <div className="workDayLabel">Qua</div>
                    </div>
                    <div className="workDay">
                        <Checkbox
                            checked={this.state.qui}
                            onChange={evt => this.setState({qui: evt.target.checked})}>
                        </Checkbox>
                        <div className="workDayLabel">Qui</div>
                    </div>
                    <div className="workDay">
                        <Checkbox
                            checked={this.state.sex}
                            onChange={evt => this.setState({sex: evt.target.checked})}>
                        </Checkbox>
                        <div className="workDayLabel">Sex</div>
                    </div>
                    <div className="workDay">
                        <Checkbox
                            checked={this.state.sab}
                            onChange={evt => this.setState({sab: evt.target.checked})}>
                        </Checkbox>
                        <div className="workDayLabel">Sáb</div>
                    </div>
                </div>

                <TextField label="Ínicio do expediente" fullwidth={true} value={this.state.inicio}
                           pattern={regexHour}
                           onChange={evt => this.setState({inicio: evt.target.value})}/>
                <TextFieldHelperText validationMsg>Formato HH:MM 24 Horas.</TextFieldHelperText>

                <div className="intervalCheckBox">
                    <Checkbox
                        checked={this.state.intervalo}
                        onChange={evt => this.setState({intervalo: evt.target.checked})}>
                    </Checkbox>
                    <div className="intervalText">Intervalo</div>
                </div>

                {this.state.intervalo &&
                <Fragment>
                    <TextField label="Ínicio do intervalo" fullwidth={true} value={this.state.initialIntervalo}
                            pattern={regexHour}
                            onChange={evt => this.setState({initialIntervalo: evt.target.value})}/>
                    <TextFieldHelperText validationMsg>Formato HH:MM 24 Horas.</TextFieldHelperText>

                    <TextField label="Fim do intervalo" fullwidth={true} value={this.state.finalIntervalo}
                            pattern={regexHour}
                            onChange={evt => this.setState({finalIntervalo: evt.target.value})}/>
                    <TextFieldHelperText validationMsg>Formato HH:MM 24 Horas.</TextFieldHelperText>
                </Fragment>}

                <TextField label="Fim do expediente" fullwidth={true} value={this.state.fim}
                           pattern={regexHour}
                           onChange={evt => this.setState({fim: evt.target.value})}/>
                <TextFieldHelperText validationMsg>Formato HH:MM 24 Horas.</TextFieldHelperText>

                <Select
                    label="Fuso"
                    options={timezone}
                    value={this.state.fuso}
                    onChange={evt => this.setState({fuso: evt.target.value})}
                />

                <Button unelevated style={{width: "100%", height: "60px", marginTop: "24px"}} onClick={this.reportWorkDay.bind(this)}>SALVAR</Button>

            </Elevation>

        )
    }
}

export default WorkDay;


