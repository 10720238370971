import * as React from 'react';
import * as PropTypes from 'prop-types';
import {inject, observer} from 'mobx-react';
import {get} from 'mobx';
import {DrawerAppContent} from '@rmwc/drawer';
import {GoogleMap, withGoogleMap, withScriptjs,} from "react-google-maps";
import MarkerWithLabel from "react-google-maps/lib/components/addons/MarkerWithLabel";
import navStore from "./domain/Nav";
import {customColor} from "./util";

let mapRef = null;

const MapWithAMarker = withScriptjs(withGoogleMap(props =>
    <GoogleMap
        defaultZoom={8}
        defaultCenter={{lat: props.latitude, lng: props.longitude}}
        ref={(ref) => {
            console.log('mapref', ref);
            if (ref) mapRef = ref
        }}

    >
        {props.children}
    </GoogleMap>
));
const INACTIVE_TIME_LIMIT = 3 * 60 * 1000;

const INACTIVE_TIME_LIMIT_HOUR = 60 * 60 * 240000;

let globalMarkers = null;
let globalAllMarkers = null;
let globalBounds = null;
let globalData = null;
let globalWatcher = 0;

function waitRef(f) {
    if (globalWatcher) clearInterval(globalWatcher);
    globalWatcher = setInterval(() => {
        if (mapRef) {
            clearInterval(globalWatcher);
            f();
        }
    }, 100)
}

@inject("mapStore", "userStore", "navStore")
@observer
export class Container extends React.Component {
    static propTypes = {
        label: PropTypes.node
    };

    state = {
        isOpen: false,
        marks: [],
        allMarks: [],
        selectedMark: {},
    };

    constructor(props) {
        super(props);
        this.mapInit = true;
        this.mapRef = null;
        this.loopCheckWatcher = 0;
    }

    componentDidMount() {
        this.mapInit = true;
        mapRef = null;
        if (globalMarkers) {
            this.setState({marks: globalMarkers, allMarks: globalAllMarkers});
        }
        if (globalBounds) {
            waitRef(() => mapRef.fitBounds(globalBounds));
        }

        let dataScan = (data) => {
            let mks = [];
            let allMks = [];
            let poke = [];

            for (let phoneNumber in data) {
                let isInterval = true;
                let item = data[phoneNumber];
                if (item.name) {

                    item.phoneNumber = phoneNumber;
                    item.offline = true;
                    if (item.photo) item.photo = item.photo.replace("generation", "generation2");
                    let member = {};
                    let group = this.props.userStore.groups.find(x => x.members.find(y => y && item && y.phoneNumber === item.phoneNumber));
                    if (group) {
                        member = group.members.find(x => x && item && x.phoneNumber === item.phoneNumber);
                        if (item.updatedAt > (Date.now() - INACTIVE_TIME_LIMIT_HOUR)) {
                            item.offline = false;
                        }
                    }
                    if (item.updatedAt < Date.now() - INACTIVE_TIME_LIMIT && (!item.offline || item.status !== 1)) {
                        poke.push(item.phoneNumber);
                    }

                    item.workStatus = member.workStatus;
                    if (member.workStatus === "work") {
                        mks.push(item);
                    }
                    allMks.push(item);
                }
            }
            this.setState({
                marks: mks,
                allMarks: allMks
            });
            globalMarkers = mks;
            globalAllMarkers = allMks;

            if (poke.length) {
                this.props.mapStore.pokeContact(poke);
            }

            if (this.mapInit && mks.length) {
                this.mapInit = false;
                let bounds = new google.maps.LatLngBounds();
                for (let mark of mks) {
                    bounds.extend(new google.maps.LatLng(mark.latitude, mark.longitude));
                    bounds.extend(new google.maps.LatLng(mark.latitude + 0.005, mark.longitude + 0.005));
                    bounds.extend(new google.maps.LatLng(mark.latitude - 0.005, mark.longitude - 0.005));
                }
                if (mapRef) {
                    mapRef.fitBounds(bounds);
                    globalBounds = bounds;
                }
            }
        };

        this.props.mapStore.watch(data => {
            globalData = data;
            dataScan(globalData);
            if (!this.loopCheckWatcher) {
                this.loopCheckWatcher = setInterval(() => dataScan(globalData), 60000);
            }
        });
    }

    findUserInMap(user) {
        const {marks, allMarks} = this.state;
        if (!user || !mapRef) {
            return;
        }

        let bounds = new google.maps.LatLngBounds();

        let mark = allMarks.find(x => x && user && x.phoneNumber === user.phoneNumber);
        if (marks && mark) {
            let markers = [];
            markers = marks.filter(x => x && user && x.phoneNumber !== user.phoneNumber);

            this.setState({
                selectedMark: mark,
                marks: [...markers, mark]
            });
        }

        if (mark) {
            bounds.extend(new google.maps.LatLng(mark.latitude, mark.longitude));
            bounds.extend(new google.maps.LatLng(mark.latitude + 0.005, mark.longitude + 0.005));
            bounds.extend(new google.maps.LatLng(mark.latitude - 0.005, mark.longitude - 0.005));
            mapRef.fitBounds(bounds);
        }

    }

    componentWillUnmount() {
        this.mapInit = false;
        if (this.loopCheckWatcher) {
            clearInterval(this.loopCheckWatcher);
        }
    }

    showMark(mark) {
        const {selectedMark} = this.state;
        if (selectedMark && mark && selectedMark.phoneNumber === mark.phoneNumber) return true;

        let group = this.props.userStore.groups.find(x => x.members.find(y => y && mark && y.phoneNumber === mark.phoneNumber));
        if (group) {
            let member = group.members.find(x => x && mark && x.phoneNumber === mark.phoneNumber);
            if (get(member, 'workStatus') !== "work") {
                return false;
            }
        }

        return true;
    }

    render() {
        const {children, label} = this.props;
        var markerStyling = {
            clear: "both", display: "inline-block", backgroundColor: "#004691", fontWeight: '500',
            color: "#FFFFFF", boxShadow: "rgba(63, 63, 63, 0.51) 4px 3px 13px 0px", borderRadius: "20px",
            whiteSpace: "nowrap", width: "40px", height: "40px", textAlign: "center", overflow: "visible", opacity: 1
        };
        console.log('Marks', this.state.marks);
        return (
            <DrawerAppContent tag="main" className="app__content">
                <MapWithAMarker
                    googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBlogweKPhxAblasHYXtYKRFcziuqQ_rA4&v=3.exp&libraries=geometry,drawing,places"
                    loadingElement={<div style={{height: `100%`}}/>}
                    latitude={-15.8267} longitude={-47.9218}
                    containerElement={<div className="mapContainer"/>}
                    mapElement={<div style={{height: `100%`}}/>}
                >
                    {this.state.marks.map((mark, index) =>
                        this.showMark(mark) && <MarkerWithLabel
                            labelAnchor={new google.maps.Point(20, 20)}
                            labelStyle={markerStyling}
                            icon={{
                                url: '/none.svg',
                            }}
                            defaultZIndex={index * 10}  // Muda o Z-index do mark
                            key={mark.phoneNumber + index * 10} // Força o React a redraw o novo marker
                            onClick={e => {
                                navStore.gotoScreen(navStore.SCREEN.MEMBER_DETAIL, mark)
                            }}
                            position={{lat: mark.latitude, lng: mark.longitude}}>
                            <div className="markerRadar">
                                {mark.photo && (!mark.offline || mark.status == 1) && <div className="markerContent"
                                                                                           style={{backgroundImage: "url(" + mark.photo.replace(".png", "_32.png") + ")"}}>&nbsp;</div>}
                                {!mark.photo && (!mark.offline || mark.status == 1) && <div className="markerContent"
                                                                                            style={{backgroundColor: customColor(mark.name)}}>{mark.name[0].toUpperCase()}</div>}
                                {mark.photo && mark.offline && mark.status !== 1 && <div>
                                    <div className="markerContent"
                                         style={{backgroundImage: "url(" + mark.photo.replace(".png", "_32.png") + ")"}}>&nbsp;</div>
                                    <div className="contactMapOffline"></div>
                                </div>}
                                {!mark.photo && mark.offline && mark.status !== 1 && <div>
                                    <div className="markerContent"
                                         style={{backgroundColor: customColor(mark.name)}}>{mark.name[0].toUpperCase()}</div>
                                    <div className="contactMapOffline"></div>
                                </div>}
                            </div>
                        </MarkerWithLabel>)}

                </MapWithAMarker>
            </DrawerAppContent>
        );
    }
}

export function getMapRef() {
    return mapRef
};
export default Container;
